export default {
    
    documents: function() {
        return axios.get('/api/documents', { responseType: 'json' })
    },

    addDocument: function(doc, filters, folder) {
        let formData = new FormData();
        formData.append("doc", doc);
        formData.append("filtersOrganization", filters.organizations);
        formData.append("filtersCanton", filters.cantons);
        formData.append("filtersJob", filters.jobs);
        formData.append("folder", folder);

        return axios.post('/api/documents', formData, { 
                responseType: 'json', 
                headers: { 
                    'Content-Type': 'multipart/form-data' 
                }
            })
    },

    deleteDocument: function(docName) {
        return axios.post('/api/documents/delete', { docName, responseType: 'json' })
    },

    downloadDocument: function(docName) {
        return axios.get('/api/documents/download', { params: { docName }, responseType: 'blob' })
    },

}