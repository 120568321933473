export default {

    settings: function() {
        return axios.get('/api/settings', { responseType: 'json' })
    },

    editSettings: function(name, value) {
        return axios.post('/api/settings', { name, value, responseType: 'json' })
    },
    
}   

