import axios from "axios"

export default {

    organizations: function() {
        return axios.get('/api/organizations', { responseType: 'json' })
    },

    addOrganization: function(organization) {
        return axios.post(`/api/organizations`, { organization, responseType: "json" });
    },

    deleteOrganization: function(id) {
        return axios.delete(`/api/organizations/${id}`, { id, responseType: "json" });
    },

    editOrganization: function(id, organization) {
        return axios.put(`/api/organizations/${id}`, { id, organization, responseType: 'json' })
    },
}