<template>
    <div id="card" class="rounded bg-info text-white" :class="{ success: verificationSuccess, failure: verificationFailure }">
        <div v-if="verificationSuccess" class="text-center content font-weight-bold">
            {{ $t('verification.success') }}
            <i class="fas fa-check fa-lg mt-1 ml-2"></i>
        </div>
        <div v-else-if="verificationFailure" class="text-center content font-weight-bold">
            {{ $t('verification.failure') }}
            <i class="fas fa-times fa-lg mt-1 ml-2"></i>
        </div>
        <half-circle-spinner v-else class="mx-auto"
            :animation-duration="800"
            :size="60"
            :color="'#FFFFFF'"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { HalfCircleSpinner } from 'epic-spinners'

export default {
    components: {
        HalfCircleSpinner
    },
    mounted() {
        this.verify(this.$route.query.hash)
    },
    computed: {
        ...mapState('auth', [ 'verificationSuccess', 'verificationFailure' ]),
    },
    methods: {
        ...mapActions('auth', [ 'verify' ]),
    }
}
</script>

<style scoped>
    #card {
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, .3);
        width: 340px;
        height: 120px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -60px 0 0 -170px; /* apply negative top and left margins to truly center the element */
        padding: 30px;
    }
    .content {
        padding-top: 17px;
    }
    .success {
        background-color: green !important;
    }
    .failure {
        background-color: red !important;
    }
</style>