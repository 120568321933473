<template>
    <div v-if="value" class="mt-2">

        <h5 v-if="value.name">{{ value.name }}</h5>
        <h5 v-else>{{ $t('organization.title') }}</h5>
        
        <div class="border rounded p-3">

            <div class="form-row">
                <div class="form-group col-md-8 m-0">
            
                    <!-- Name -->
                    <label class="form-label">
                        {{ $t('forms.content.last-name') }}
                        <span v-if="v.name.required === false">*</span>
                    </label>
                    <input class="form-control" :class="{ 'is-invalid': validationStatus(v.name) }" v-model="value.name" type="text" />
                    <div v-if="!v.name.required" class="invalid-feedback">{{ $t('forms.validation.last-name') }}</div>

                </div>
                <div class="form-group col-md-4 m-0">
            
                    <!-- Canton -->
                    <label class="form-label">
                        {{ $t('forms.content.canton') }}
                        <span v-if="v.canton.required === false">*</span>
                    </label>
                    <select class="form-control" :class="{ 'is-invalid': validationStatus(v.canton) }" v-model="value.canton">
                        <option value="">{{ $t('forms.content.select') }}</option>
                        <option v-for="canton in ordreredCantons" :key="canton.fr" :value="canton">
                            {{ canton[$i18n.locale] }}
                        </option>
                    </select>
                    <div v-if="!v.canton.required" class="invalid-feedback">{{ $t('forms.validation.canton') }}</div>

                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-8 m-0">
            
                    <!-- Email -->
                    <label class="form-label mt-2">
                        {{ $t('forms.content.email') }}
                        <span v-if="v.email.required === false">*</span>
                    </label>
                    <input class="form-control" :class="{ 'is-invalid': validationStatus(v.email) }" v-model="value.email" type="email" />
                    <div v-if="!v.email.required" class="invalid-feedback">{{ $t('forms.validation.email') }}</div>
                    <div v-else-if="!v.email.email" class="invalid-feedback">{{ $t('forms.validation.email-format') }}</div>

                </div>
                <div class="form-group col-md-4 m-0">
            
                    <!-- Status -->
                    <label class="form-label mt-2">
                        {{ $t('forms.content.status') }}
                        <span v-if="v.status.required === false">*</span>
                    </label>
                    <select class="form-control" :class="{ 'is-invalid': validationStatus(v.status) }" v-model="value.status">
                        <option value="">{{ $t('forms.content.select') }}</option>
                        <option v-for="status in orderedStatus" :key="status.fr" :value="status">
                            {{ status[$i18n.locale] }}
                        </option>
                    </select>
                    <div v-if="!v.status.required" class="invalid-feedback">{{ $t('forms.validation.status') }}</div>

                </div>
            </div>
            
            <!-- URL -->
            <label class="form-label mt-2">
                {{ $t('forms.content.url') }}
                <span v-if="v.url.required === false">*</span>
            </label>
            <input class="form-control" :class="{ 'is-invalid': validationStatus(v.url) }" v-model="value.url" type="url" />
            <div v-if="!v.url.required" class="invalid-feedback">{{ $t('forms.validation.url-format') }}</div>

            <div class="form-row">
                <div class="form-group col-md-4 m-0">

                    <!-- Collaborators -->
                    <label class="form-label mt-2">
                        {{ $t('forms.content.collaborators') }}
                        <span v-if="v.collaborators.required === false">*</span>
                    </label>
                    <input class="form-control" :class="{ 'is-invalid': validationStatus(v.collaborators) }" v-model="value.collaborators" type="number" />
                    <div v-if="!v.collaborators.required" class="invalid-feedback">{{ $t('forms.validation.collaborators') }}</div>

                </div>
                <div class="form-group col-md-4 m-0">
            
                    <!-- Executives -->
                    <label class="form-label mt-2">
                        {{ $t('forms.content.executives') }}
                        <span v-if="v.executives.required === false">*</span>
                    </label>
                    <input class="form-control" :class="{ 'is-invalid': validationStatus(v.executives) }" v-model="value.executives" type="number" />
                    <div v-if="!v.executives.required" class="invalid-feedback">{{ $t('forms.validation.executives') }}</div>

                </div>
                <div class="form-group col-md-4 m-0">
            
                    <!-- EPT -->
                    <label class="form-label mt-2">
                        {{ $t('forms.content.ept') }}
                        <span v-if="v.ept.required === false">*</span>
                    </label>
                    <input class="form-control" :class="{ 'is-invalid': validationStatus(v.ept) }" v-model="value.ept" type="number" step="0.01" />
                    <div v-if="!v.ept.required" class="invalid-feedback">{{ $t('forms.validation.ept') }}</div>

                </div>
            </div>

            <!-- Address -->
            <AddressForm v-model="value.address" :v="v.address" />
            
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AddressForm from './AddressForm.vue'

export default {
    components: {
        AddressForm
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        v: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState('app', [ 'cantons', 'status' ]),
        ...mapState('auth', [ 'errorMessage', 'isLoading' ]),

        ordreredCantons() {
            if (this.cantons) {
                return [...this.cantons].sort((a, b) => 
                    (a[this.$i18n.locale] > b[this.$i18n.locale]) ? 1 : 
                    (b[this.$i18n.locale] > a[this.$i18n.locale]) ? -1 : 0)
            }

            return []
        },

        orderedStatus() {
            if (this.status) {
                return [...this.status].sort((a, b) => 
                    (a[this.$i18n.locale] > b[this.$i18n.locale]) ? 1 : 
                    (b[this.$i18n.locale] > a[this.$i18n.locale]) ? -1 : 0)
            }

            return []
        }
    },
    methods: {
        ...mapActions('auth', [ 'resetErrors', 'login' ]),

        validationStatus: function(validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },
    }
}
</script>

<style scoped>

</style>