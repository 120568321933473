
export default {

    login: function(user) {
        return axios.post('/api/login', { email: user.email, password: user.password, responseType: 'json' })
    },

    change: function(email) {
        return axios.post('/api/change', { email, responseType: 'json' })
    },

    verify: function(hash) {
        return axios.post('/api/verify-user', { hash, responseType: 'json' })
    },

    employeeFormData: function() {
        return axios.get('/api/employee-form-data', { responseType: 'json' })
    },

    register: function(employee) {
        return axios.post('/api/register', { employee, responseType: 'json' })
    },

    logout: function() {
        return axios.post('/api/logout');
    },

    updateEmail: function(id, email) {
        return axios.post('/api/email', { id, email, responseType: 'json' })
    },

    updatePassword: function(id, infos) {
        return axios.post('/api/password', { id, infos, responseType: 'json' })
    }
}   

