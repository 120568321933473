<template>
    <div id="pageContainer">

        <h2 class="unselectable title font-weight-bold mb-0">{{ $t('organizations.title') }}</h2>

        <div v-if="editingOrganization">

            <!-- Form -->
            <OrganizationForm v-model="organization" :v="$v.organization" />

            <!-- Error banner -->
            <div v-if="errors.addOrganization" class="alert alert-danger text-center mt-4 mb-0" role="alert">{{ errors.addOrganization }}</div>

            <!-- Buttons -->
            <div class="form-row">
                <div class="form-group col-md-6 m-0">
                    
                    <!-- Cancel -->
                    <button class="btn btn-danger mt-4 font-weight-bold w-100 mt-2" @click="cancel">
                        {{ $t('organization.cancel') }}
                    </button>

                </div>
                <div class="form-group col-md-6 m-0">
            
                    <!-- Save -->
                    <button class="btn btn-info mt-4 font-weight-bold w-100 mt-2" @click="submit">
                        <div v-if="true" class="pointer m-0">{{ $t('organization.save') }}</div>
                        <half-circle-spinner v-else class="mx-auto"
                            :animation-duration="800"
                            :size="23"
                            :color="'#ffffff'"
                        />
                    </button>

                </div>
            </div>
        </div>

        <div v-else>

            <!-- Organization creation card -->
            <b-card class="mt-3">

                <!-- Title -->
                <h5 class="mb-3">{{ $t('organizations.add-title') }}</h5>

                <!-- Save button -->
                <button @click="setEditingOrganization(true)" class="btn btn-info w-100">{{ $t('organizations.create') }}</button>
            </b-card>
            
            <!-- Organizations card -->
            <b-card class="mt-3">

                <!-- Search title -->
                <h5 class="mb-3">{{ $t('organizations.search-title') }}</h5>

                <!-- Search bar -->
                <b-input-group class="mt-3">
                    <b-form-input id="filter-input" v-model="filter" type="search" :placeholder="$t('employees.search')" />
                        <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">{{ $t('employees.clear') }}</b-button>
                    </b-input-group-append>
                </b-input-group>

                <b-row class="my-3">

                    <!-- Filtering title -->
                    <b-col>
                        <h5 class="m-0">{{ $t('organizations.filter-title') }}</h5>
                    </b-col>

                    <!-- Clear filters button -->
                    <b-col>
                        <div class="text-right">
                            <button type="button" class="btn btn-info" @click="clearFilters()">
                                <i class="fas fa-sync mr-1" />
                                {{ $t('employees.clear-filter') }}
                            </button>
                        </div>
                    </b-col>

                </b-row>

                <!-- Filtering -->
                <b-container class="mx-0 mw-100">

                    <!-- By organization -->
                    <b-row class="mb-2" align-v="center">
                        <b-col lg class="pr-1 pl-0">{{ $t('organizations.filters.name') }}</b-col>
                        <b-col lg class="px-1">
                            <select class="form-control" v-model="filtersOrganization[0]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="organization in ordreredOrganizations" :key="organization.name">
                                    {{ organization.name }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select class="form-control" v-model="filtersOrganization[1]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="organization in ordreredOrganizations" :key="organization.name">
                                    {{ organization.name }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select class="form-control" v-model="filtersOrganization[2]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="organization in ordreredOrganizations" :key="organization.name">
                                    {{ organization.name }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="pl-1 pr-0">
                            <select class="form-control" v-model="filtersOrganization[3]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="organization in ordreredOrganizations" :key="organization.name">
                                    {{ organization.name }}
                                </option>
                            </select>
                        </b-col>
                    </b-row>

                    <!-- By canton -->
                    <b-row class="mb-2" align-v="center">
                        <b-col lg class="pr-1 pl-0">{{ $t('employees.filters.canton') }}</b-col>
                        <b-col lg class="px-1">
                            <select class="form-control" v-model="filtersCanton[0]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="canton in ordreredCantons" :key="canton.fr" :value="canton.id">
                                    {{ canton[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select class="form-control" v-model="filtersCanton[1]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="canton in ordreredCantons" :key="canton.fr" :value="canton.id">
                                    {{ canton[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select class="form-control" v-model="filtersCanton[2]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="canton in ordreredCantons" :key="canton.fr" :value="canton.id">
                                    {{ canton[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="pl-1 pr-0">
                            <select class="form-control" v-model="filtersCanton[3]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="canton in ordreredCantons" :key="canton.fr" :value="canton.id">
                                    {{ canton[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                    </b-row>

                    <!-- By status -->
                    <b-row class="mb-2" align-v="center">
                        <b-col lg class="pr-1 pl-0">{{ $t('employees.filters.status') }}</b-col>
                        <b-col lg class="px-1">
                            <select class="form-control" v-model="filtersStatus[0]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="status in ordreredStatus" :key="status.fr" :value="status.id">
                                    {{ status[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select class="form-control" v-model="filtersStatus[1]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="status in ordreredStatus" :key="status.fr" :value="status.id">
                                    {{ status[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select class="form-control" v-model="filtersStatus[2]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="status in ordreredStatus" :key="status.fr" :value="status.id">
                                    {{ status[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="pl-1 pr-0">
                            <select class="form-control" v-model="filtersStatus[3]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="status in ordreredStatus" :key="status.fr" :value="status.id">
                                    {{ status[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                    </b-row>

                </b-container>

                <!-- Organizations -->
                <div class="overflow-auto mt-2">
                    
                    <!-- Deletion modal -->
                    <Modal id="deletionModal"
                        :title="$t('organizations.delete-modal-title')"
                        :cancelMessage="$t('organizations.delete-modal-cancel')"
                        :confirmMessage="$t('organizations.delete-modal-confirm')"
                        v-on:confirm="destroyOrganization">
                        
                        <label v-if="deletingOrganization" v-text="$t('forms.validation.confirm-delete', { content: deletingOrganization.name })" />
                    
                    </Modal>
                    
                    <!-- Table -->
                    <b-table v-if="mappedOrganizations.length > 0" class="mb-0"
                        id="my-table"
                        :items="mappedOrganizations"
                        :fields="fields"
                        :filter="filter"
                        :busy="loading.organizations"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :striped="true"
                        :borderless="true"
                        :hover="true"
                        small
                        sort-icon-left
                    >
                        <template #table-busy>
                            <div class="text-center text-info my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>

                        <template #cell(actions)="row">

                            <!-- Details button -->
                            <button type="button" class="btn btn-info" @click="selectOrganization(row.item.name)">
                                <i class="fas fa-search mr-1" />
                                {{ $t('organizations.details') }}
                            </button>

                            <!-- Delete button -->
                            <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deletionModal" @click="setDeletingOrganization(row.item.name)" :disabled="loading.deleteOrganization">
                                <i class="fas fa-times mr-1" v-if="!loading.deleteOrganization" />
                                <label style="cursor: pointer;" v-if="!loading.deleteOrganization" class="m-0">{{ $t('organizations.delete') }}</label>
                                <half-circle-spinner v-else class="mx-auto"
                                    :animation-duration="800"
                                    :size="23"
                                    :color="'#ffffff'"
                                />
                            </button>

                        </template>
                    </b-table>

                    <!-- Empty content -->
                    <div v-else class="alert alert-secondary text-center m-0" role="alert">
                        <i class="fas fa-exclamation fa-lg mt-1 mr-2"/>
                        {{ $t('organizations.empty') }}
                    </div>

                    <!-- Pagination -->
                    <b-pagination class="mt-4" v-if="mappedOrganizations.length > perPage"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        align="center"
                        aria-controls="my-table"
                    >
                        <template #page="{ page }">
                            <b>{{ page }}</b>
                        </template>
                    </b-pagination>
                </div>

            </b-card>

        </div>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { HalfCircleSpinner } from 'epic-spinners'
import { required, email } from 'vuelidate/lib/validators'
import OrganizationForm from './Forms/OrganizationForm.vue'
import Modal from './Forms/Modal'

export default {
    components: {
        HalfCircleSpinner,
        OrganizationForm,
        Modal
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            filter: null,
            filtersCanton: [ '', '', '', '' ],
            filtersStatus: [ '', '', '', '' ],
            filtersOrganization: [ '', '', '', '' ],
            organizationDefault: {
                id: "",
                name: "",
                canton: "",
                email: "",
                status: "",
                url: "",
                collaborators: "",
                executives: "",
                ept: "",
                address: {
                    phone: "",
                    address: "",
                    zip: "",
                    city: "",
                    country: ""
                }
            },
            organization: null,
            deletingOrganization: null
        }
    },
    validations: {
        organization: {
            id: { },
            name: { required },
            canton: { required },
            email: { required, email },
            status: { required },
            url: { },
            collaborators: { },
            executives: { },
            ept: { },
            address: {
                phone: { },
                address: { required },
                zip: { required },
                city: { required },
                country: { required }
            }
        }
    },
    mounted() {
        this.init()
        this.loadOrganizations()
        this.getFormData()
    },
    watch: {
        // Reset organization values when leaving the edit 
        editingOrganization: function(editing) {
            if (!editing) {
                this.organization = JSON.parse(JSON.stringify(this.organizationDefault))
            }
        }
    },
    computed: {
        ...mapState('app', [ 'editingOrganization', 'organizations', 'loading', 'errors', 'cantons', 'status' ]),

        rows() {
            return this.mappedOrganizations.length
        },

        fields() {
            return [
                { key: 'name', label: this.$t('organizations.filters.name'), tdClass: 'align-middle p-2', sortable: true },
                { key: 'canton', label: this.$t('organizations.filters.canton'), tdClass: 'align-middle p-2', sortable: true },
                { key: 'status', label: this.$t('organizations.filters.status'), tdClass: 'align-middle p-2', sortable: true },
                { key: 'email', label: this.$t('organizations.filters.email'), tdClass: 'align-middle p-2' },
                { key: 'actions', label: this.$t('organizations.filters.actions'), thClass: 'text-right', tdClass: 'align-middle text-right text-nowrap p-2' }
            ]
        },

        mappedOrganizations() {
            if (this.organizations) {
                return this.organizations.map(organization => ({
                    // Mapping
                    id: organization.id,
                    canton: organization.canton[this.$i18n.locale],
                    name: organization.name,
                    email: organization.email,
                    status: organization.status[this.$i18n.locale],
                    statusObject: organization.status,
                    cantonObject: organization.canton
                })).filter(organization => {
                    // Filtering
                    return (this.filtersCanton.includes(organization.cantonObject.id) || !this.filtersCanton.some((j) => j != ''))
                        && (this.filtersStatus.includes(organization.statusObject.id) || !this.filtersStatus.some((s) => s != ''))
                        && (this.filtersOrganization.includes(organization.name) || !this.filtersOrganization.some((o) => o != ''))
                })
            }

            return []
        },

        ordreredCantons() {
            if (this.cantons) {
                return [...this.cantons].sort((a, b) => 
                    (a[this.$i18n.locale] > b[this.$i18n.locale]) ? 1 : 
                    (b[this.$i18n.locale] > a[this.$i18n.locale]) ? -1 : 0)
            }

            return []
        },

        ordreredStatus() {
            if (this.status) {
                return [...this.status].sort((a, b) => 
                    (a[this.$i18n.locale] > b[this.$i18n.locale]) ? 1 : 
                    (b[this.$i18n.locale] > a[this.$i18n.locale]) ? -1 : 0)
            }

            return []
        },

        ordreredOrganizations() {
            if (this.organizations) {
                return [...this.organizations].sort((a, b) => 
                    (a.name > b.name) ? 1 : 
                    (b.name > a.name) ? -1 : 0)
            }

            return []
        }
    },
    methods: {
        ...mapActions('app', [ 'setEditingOrganization', 'resetErrors', 'loadOrganizations', 
            'deleteOrganization', 'addOrganization', 'editOrganization', 'getFormData' ]),

        clearFilters: function() {
            this.filtersCanton = [ '', '', '', '' ]
            this.filtersStatus = [ '', '', '', '' ]
            this.filtersOrganization = [ '', '', '', '' ]
        },

        init: function() {
            this.organization = JSON.parse(JSON.stringify(this.organizationDefault))
        },

        cancel: function() {
            this.resetErrors()
            this.setEditingOrganization(false)
        },

        selectOrganization(organizationName) {
            let organization = this.organizations.filter(organization => organization.name == organizationName)[0]
            this.organization = JSON.parse(JSON.stringify(organization))
            this.setEditingOrganization(true)
        },

        setDeletingOrganization(organizationName) {
            this.deletingOrganization = this.organizations.filter(organization => organization.name == organizationName)[0]
        },

        destroyOrganization() {
            this.deleteOrganization(this.deletingOrganization)
        },

        submit: function() {

            // Reset any previous error
            this.resetErrors()

            // Prevent login if valdiation contains errors
            this.$v.$touch();
            if (this.$v.$pending || this.$v.$error) return

            // Reset validation otherwise
            this.$v.$reset()

            // Save or update organization
            if (this.organization.id !== "") {
                this.editOrganization({ id: this.organization.id, organization: this.organization })
            } else {
                this.addOrganization(this.organization)
            }
        }
    }
}

</script>

<style scoped>
    #pageContainer {
        padding: 50px 100px;
        background-color: white;
        margin: auto;
        margin-top: 56px;
    }
</style>