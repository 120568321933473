<template>
    <div id="pageContainer">

        <h2 class="unselectable title font-weight-bold mb-0">{{ $t('employees.title') }}</h2>

        <!-- Edit employee -->
        <div v-if="editingEmployee" class="mt-2">

            <!-- Edit title -->
            <h5>{{ $t('employees.edit-title') }}</h5>

            <!-- Employee Form -->
            <EmployeeDetailsForm v-model="employee" :v="$v.employee" />

            <!-- Job Form -->
            <JobDetailsForm v-for="(jobDetails, i) in employee.jobs" :key="i"
                :id="i" 
                :count="employee.jobs.length"
                :value="jobDetails"
                :v="$v.employee.jobs.$each[i]"
                v-on:change="changePickedJob"
                v-on:delete="deleteJob" />

            <!-- Add job form button -->
            <button @click="addJobDetails()" class="btn btn-info w-100 my-4 font-weight-bold" :disabled="employee.jobs.length >= 4" type="button">
                <div v-if="employee.jobs.length < 4">
                    <i class="fas fa-plus fa-lg mt-1 mr-2"></i>
                    {{ $t('register.form.add-job-button') }}
                </div>
                <div v-else>{{ $t("register.form.add-job-button-limit") }}</div>
            </button>

            <!-- Error banner -->
            <div v-if="errors.editEmployee" class="alert alert-danger text-center mb-4 mb-0" role="alert">{{ errors.editEmployee }}</div>

            <!-- Buttons -->
            <div class="form-row">
                <div class="form-group col-md-6 m-0">
                    
                    <!-- Cancel -->
                    <button class="btn btn-danger font-weight-bold w-100 mt-0" @click="cancel">
                        {{ $t('organization.cancel') }}
                    </button>

                </div>
                <div class="form-group col-md-6 m-0">
            
                    <!-- Save -->
                    <button class="btn btn-info font-weight-bold w-100 mt-0" @click="submit">
                        <div v-if="true" class="pointer m-0">{{ $t('organization.save') }}</div>
                        <half-circle-spinner v-else class="mx-auto"
                            :animation-duration="800"
                            :size="23"
                            :color="'#ffffff'"
                        />
                    </button>

                </div>
            </div>
        </div>

        <!-- Employees -->
        <div v-else>
            
            <!-- Employees card -->
            <b-card class="mt-3">

                <!-- Search title -->
                <h5 class="mb-3">{{ $t('employees.search-title') }}</h5>

                <!-- Search bar -->
                <b-input-group class="mb-2">
                    <b-form-input id="filter-input" v-model="filter" type="search" :placeholder="$t('employees.search')" />
                        <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">{{ $t('employees.clear') }}</b-button>
                    </b-input-group-append>
                </b-input-group>

                <b-row class="mt-3">

                    <!-- Filtering title -->
                    <b-col>
                        <h5 class="m-0">{{ $t('employees.filter-title') }}</h5>
                    </b-col>

                    <!-- Clear filters button -->
                    <b-col>
                        <div class="text-right">
                            <button type="button" class="btn btn-info" @click="clearFilters()">
                                <i class="fas fa-sync mr-1" />
                                {{ $t('employees.clear-filter') }}
                            </button>
                        </div>
                    </b-col>

                </b-row>

                <!-- Filtering -->
                <b-container class="mx-0 mw-100">

                    <!-- Main job checkbox -->
                    <b-row class="mb-3" align-v="center">
                        <b-form-checkbox class="unselectable" v-model="mainJobOnly" switch>
                            {{ $t('employees.main-job-only') }}
                        </b-form-checkbox>
                    </b-row>

                    <!-- By main job -->
                    <b-row class="mb-2" align-v="center">
                        <b-col lg class="pr-1 pl-0">{{ $t('employees.filters.job') }}</b-col>
                        <b-col lg class="px-1">
                            <select v-if="jobs" class="form-control" v-model="filtersJob[0]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="job in jobs" :key="job.fr" :value="job.id">
                                    {{ job[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select v-if="jobs" class="form-control" v-model="filtersJob[1]" >
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="job in jobs" :key="job.fr" :value="job.id">
                                    {{ job[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select v-if="jobs" class="form-control" v-model="filtersJob[2]" >
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="job in jobs" :key="job.fr" :value="job.id">
                                    {{ job[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="pl-1 pr-0">
                            <select v-if="jobs" class="form-control" v-model="filtersJob[3]" >
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="job in jobs" :key="job.fr" :value="job.id">
                                    {{ job[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                    </b-row>

                    <!-- By organization -->
                    <b-row class="mb-2" align-v="center">
                        <b-col lg class="pr-1 pl-0">{{ $t('employees.filters.organization') }}</b-col>
                        <b-col lg class="px-1">
                            <select v-if="organizations" class="form-control" v-model="filtersOrganization[0]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="organization in orderedOrganizations" :key="organization.name" :value="organization.id">
                                    {{ organization.name }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select v-if="organizations" class="form-control" v-model="filtersOrganization[1]" >
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="organization in orderedOrganizations" :key="organization.name" :value="organization.id">
                                    {{ organization.name }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select v-if="organizations" class="form-control" v-model="filtersOrganization[2]" >
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="organization in orderedOrganizations" :key="organization.name" :value="organization.id">
                                    {{ organization.name }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="pl-1 pr-0">
                            <select v-if="organizations" class="form-control" v-model="filtersOrganization[3]" >
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="organization in orderedOrganizations" :key="organization.name" :value="organization.id">
                                    {{ organization.name }}
                                </option>
                            </select>
                        </b-col>
                    </b-row>

                    <!-- By status -->
                    <b-row class="mb-2" align-v="center">
                        <b-col lg class="pr-1 pl-0">{{ $t('employees.filters.status') }}</b-col>
                        <b-col lg class="px-1">
                            <select v-if="status" class="form-control" v-model="filtersStatus[0]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="status in orderedStatus" :key="status.fr" :value="status.id">
                                    {{ status[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select v-if="status" class="form-control" v-model="filtersStatus[1]" >
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="status in orderedStatus" :key="status.fr" :value="status.id">
                                    {{ status[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select v-if="status" class="form-control" v-model="filtersStatus[2]" >
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="status in orderedStatus" :key="status.fr" :value="status.id">
                                    {{ status[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="pl-1 pr-0">
                            <select v-if="status" class="form-control" v-model="filtersStatus[3]" >
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="status in orderedStatus" :key="status.fr" :value="status.id">
                                    {{ status[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                    </b-row>

                    <!-- By canton -->
                    <b-row class="mb-2" align-v="center">
                        <b-col lg class="pr-1 pl-0">{{ $t('employees.filters.canton') }}</b-col>
                        <b-col lg class="px-1">
                            <select v-if="cantons" class="form-control" v-model="filtersCanton[0]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="canton in orderedCantons" :key="canton.fr" :value="canton.id">
                                    {{ canton[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select v-if="cantons" class="form-control" v-model="filtersCanton[1]" >
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="canton in orderedCantons" :key="canton.fr" :value="canton.id">
                                    {{ canton[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="px-1">
                            <select v-if="cantons" class="form-control" v-model="filtersCanton[2]" >
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="canton in orderedCantons" :key="canton.fr" :value="canton.id">
                                    {{ canton[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                        <b-col lg class="pl-1 pr-0">
                            <select v-if="cantons" class="form-control" v-model="filtersCanton[3]" >
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="canton in orderedCantons" :key="canton.fr" :value="canton.id">
                                    {{ canton[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col>
                    </b-row>
                    
                    <!-- By gender -->
                    <b-row class="mb-2" align-v="center">
                        <b-col lg class="pr-1 pl-0">{{ $t('employees.filters.gender') }}</b-col>
                        <b-col lg class="px-1">
                            <select v-if="genders" class="form-control" v-model="filtersGender[0]">
                                <option value="">{{ $t('forms.content.select') }}</option>
                                <option v-for="gender in genders" :key="gender.fr" :value="gender.id">
                                    {{ gender[$i18n.locale] }}
                                </option>
                            </select>
                        </b-col><b-col lg class="px-1"/><b-col lg class="px-1"/><b-col lg class="px-1"/>
                    </b-row>

                </b-container>

                <!-- Employees -->
                <div class="overflow-auto mt-3">
                    
                    <!-- Deletion modal -->
                    <Modal id="deletionModal"
                        :title="$t('employees.delete-modal-title')"
                        :cancelMessage="$t('employees.delete-modal-cancel')"
                        :confirmMessage="$t('employees.delete-modal-confirm')"
                        v-on:confirm="destroyEmployee">
                        
                        <label v-if="deletingEmployee" v-text="$t('forms.validation.confirm-delete', { content: (deletingEmployee.last_name + ' ' + deletingEmployee.first_name) })" />
                    
                    </Modal>

                    <!-- Single match -->
                    <label v-if="mappedEmployees.length == 1 && !loading.employees" class="mb-2" v-text="$t('employees.search-number-single')" />
                    
                    <!-- Number of matches -->
                    <label v-else-if="mappedEmployees.length > 1 && !loading.employees" class="mb-2" v-text="$t('employees.search-number', { number: mappedEmployees.length })" />
                    
                    <!-- Table -->
                    <b-table v-if="mappedEmployees.length > 0" class="mb-0"
                        id="my-table"
                        :items="mappedEmployees"
                        :fields="fields"
                        :filter="filter"
                        :busy="loading.employees"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :striped="true"
                        :borderless="true"
                        :hover="true"
                        small
                        sort-icon-left
                    >
                        <template #table-busy>
                            <div class="text-center text-info my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>

                        <template #cell(actions)="row">

                            <!-- Details button -->
                            <button type="button" class="btn btn-info" @click="selectEmployee(row.item.id)">
                                <i class="fas fa-search mr-1" />
                                {{ $t('organizations.details') }}
                            </button>

                            <!-- Delete button -->
                            <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deletionModal" @click="setDeletingEmployee(row.item.id)" :disabled="loading.deleteEmployee">
                                <i class="fas fa-times mr-1" v-if="!loading.deleteEmployee" />
                                <label style="cursor: pointer;" v-if="!loading.deleteEmployee" class="pointer m-0">{{ $t('organizations.delete') }}</label>
                                <half-circle-spinner v-else class="mx-auto"
                                    :animation-duration="800"
                                    :size="23"
                                    :color="'#ffffff'"
                                />
                            </button>

                        </template>
                    </b-table>

                    <!-- Empty content -->
                    <div v-else class="alert alert-secondary text-center m-0" role="alert">
                        <i class="fas fa-exclamation fa-lg mt-1 mr-2"/>
                        {{ $t('employees.empty') }}
                    </div>

                    <!-- Pagination -->
                    <b-pagination class="mt-4" v-if="mappedEmployees.length > perPage"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        align="center"
                        aria-controls="my-table"
                    >
                        <template #page="{ page }">
                            <b>{{ page }}</b>
                        </template>
                    </b-pagination>
                </div>

            </b-card>

            <!-- Export -->
            <div class="text-right mt-2">
                <button type="button" class="btn btn-info" @click="exportCSV()" :disabled="loading.employees">
                    <i class="fas fa-file-export mr-1" />
                    {{ $t('employees.export') }}
                </button>
            </div>

        </div>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { HalfCircleSpinner } from 'epic-spinners'
import { i18n } from '../app.js' 
import EmployeeDetailsForm from './Forms/EmployeeDetailsForm'
import JobDetailsForm from './Forms/JobDetailsForm'
import Modal from './Forms/Modal'

export default {
    components: {
        HalfCircleSpinner,
        EmployeeDetailsForm,
        JobDetailsForm,
        Modal
    },
    data() {
        return {
            employee: null,
            deletingEmployee: null,
            mainJobOnly: false,
            perPage: 10,
            currentPage: 1,
            filter: null,
            filterGender: '',
            filtersCanton: [ '', '', '', '' ],
            filtersOrganization: [ '', '', '', '' ],
            filtersStatus: [ '', '', '', '' ],
            filtersJob: [ '', '', '', '' ],
            filtersGender: [ '' ],
            job: {
                picked: 0,
                email: "",
                percentage: "",
                organization: {
                    address: {
                        phone: { },
                        address: { required },
                        zip: { required },
                        city: { required },
                        country_id: { required }
                    }
                },
                job_name: ""
            },
        }
    },
    validations: {
        employee: {
            first_name: { required },
            last_name: { required },
            gender: { required },
            birthday: { required },
            address: {
                phone: { },
                address: { required },
                zip: { required },
                city: { required },
                country: { required }
            },
            jobs: {
                $each: {
                    email: { required, email },
                    percentage: { required },
                    organization: { 
                        required,
                        address: {
                            phone: { },
                            address: { required },
                            zip: { required },
                            city: { required },
                            country: { required }
                        }
                    },
                    job_name: { required },
                }
            }
        }
    },
    mounted() {
        this.loadEmployees()
    },
    watch: {
        employees: function(newEmployees) {
            if (newEmployees) {
                newEmployees.forEach(newEmployee => {
                    if (newEmployee && newEmployee.jobs) {
                        let picked = 0
                        for (let i = 0; i < newEmployee.jobs.length; i++) {
                            if (newEmployee.jobs[i].main) {
                                picked = i
                            }
                        }

                        newEmployee.jobs.forEach(job => job.picked = picked);
                    }
                });
            }
        }
    },
    computed: {
        ...mapState('app', [ 'employees', 'organizations', 'loading', 'errors', 'jobs', 'editingEmployee', 'cantons', 'status', 'genders' ]),

        rows() {
            return this.mappedEmployees.length
        },

        fields() {
            return [
                { key: 'gender', label: this.$t('employees.filters.gender'), tdClass: 'align-middle p-2', sortable: true },
                { key: 'last_name', label: this.$t('employees.filters.last_name'), tdClass: 'align-middle p-2', sortable: true },
                { key: 'first_name', label: this.$t('employees.filters.first_name'), tdClass: 'align-middle p-2', sortable: true },
                { key: 'main_job', label: this.$t('employees.filters.main_job'), tdClass: 'align-middle p-2', sortable: true },
                { key: 'organization', label: this.$t('employees.filters.organization'), tdClass: 'align-middle p-2', sortable: true },
                { key: 'status', label: this.$t('employees.filters.status'), tdClass: 'align-middle p-2', sortable: true },
                { key: 'canton', label: this.$t('employees.filters.canton'), tdClass: 'align-middle p-2', sortable: true },
                { key: 'actions', label: this.$t('organizations.filters.actions'), thClass: 'text-right', tdClass: 'align-middle text-right text-nowrap p-2' }
            ]
        },

        mappedEmployees() {
            if (this.employees && this.jobs) {
                return this.employees.map(employee => ({

                    // Mapping
                    id: employee.id,
                    gender: employee.gender[this.$i18n.locale],
                    gender_id: employee.gender.id,
                    last_name: employee.last_name,
                    first_name: employee.first_name,
                    jobs: employee.jobs,
                    main_job: employee.jobs.find(job => job.main).job_name[this.$i18n.locale],
                    organization: employee.jobs.find(job => job.main).organization.name,
                    status: employee.jobs.find(job => job.main).organization.status[this.$i18n.locale],
                    canton: employee.jobs.find(job => job.main).organization.canton[this.$i18n.locale]

                })).filter(employee => {

                    // Filtering
                    let filtered = false
                    let jobs = employee.jobs

                    // Limit jobs to main for filtering
                    if (this.mainJobOnly)
                    {
                        jobs = [ jobs.find(job => job.main) ]
                    }
                    
                    // Find corresponding jobs
                    jobs.forEach(job =>
                    {
                        if ((this.filtersJob.includes(job.job_name.id) || !this.filtersJob.some((j) => j != ''))
                            && (this.filtersOrganization.includes(job.organization.id) || !this.filtersOrganization.some((o) => o != ''))
                            && (this.filtersStatus.includes(job.organization.status.id) || !this.filtersStatus.some((s) => s != ''))
                            && (this.filtersCanton.includes(job.organization.canton.id) || !this.filtersCanton.some((c) => c != ''))
                            && (this.filtersGender.includes(employee.gender_id) || !this.filtersGender.some((g) => g != '')))
                        {
                            filtered = true;
                        }
                    })

                    return filtered
                })
            }

            return []
        },

        orderedOrganizations() {
            if (this.organizations) {
                return [...this.organizations].sort((a, b) => (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0)
            }

            return []
        },

        orderedStatus() {
            if (this.status) {
                return [...this.status].sort((a, b) => 
                    (a[this.$i18n.locale] > b[this.$i18n.locale]) ? 1 : 
                    (b[this.$i18n.locale] > a[this.$i18n.locale]) ? -1 : 0)
            }

            return []
        },

        orderedCantons() {
            if (this.cantons) {
                return [...this.cantons].sort((a, b) => 
                    (a[this.$i18n.locale] > b[this.$i18n.locale]) ? 1 : 
                    (b[this.$i18n.locale] > a[this.$i18n.locale]) ? -1 : 0)
            }

            return []
        }
    },
    methods: {
        ...mapActions('app', [ 'loadEmployees', 'deleteEmployee', 'setEditingEmployee', 'resetErrors', 'editEmployee' ]),

        clearFilters() {
            this.filtersJob = [ '', '', '', '' ]
            this.filtersOrganization = [ '', '', '', '' ]
            this.filtersStatus = [ '', '', '', '' ]
            this.filtersCanton = [ '', '', '', '' ]
            this.filtersGender = [ '' ]
        },

        exportCSV() {

            // Get back all employees data based on filtered mapped employees
            let filteredEmployees = this.employees.filter(employee => {
                let selected = false
                this.mappedEmployees.forEach(mappedEmployee => {
                    if (mappedEmployee.id == employee.id) {
                        selected = true
                    }
                })
                return selected
            })
            
            let csvContent = "";

            // Header line
            csvContent += this.$t('forms.content.gender') + ";"
            csvContent += this.$t('forms.content.last-name') + ";"
            csvContent += this.$t('forms.content.first-name') + ";"
            csvContent += this.$t('forms.content.birthday') + ";"
            csvContent += this.$t('forms.content.phone') + ";"
            csvContent += this.$t('forms.content.address') + ";"
            csvContent += this.$t('forms.content.zip') + ";"
            csvContent += this.$t('forms.content.city') + ";"
            csvContent += this.$t('forms.content.country') + ";"
            csvContent += this.$t('forms.content.role') + ";"
            csvContent += this.$t('employees.filters.main_job') + ";"
            csvContent += this.$t('forms.content.percentage') + ";"
            csvContent += this.$t('forms.content.email') + ";"
            csvContent += this.$t('forms.content.organization') + ";"
            csvContent += this.$t('forms.content.canton') + ";"
            csvContent += this.$t('forms.content.status') + ";"
            csvContent += this.$t('forms.content.email') + ";"
            csvContent += this.$t('forms.content.url') + ";"
            csvContent += this.$t('forms.content.collaborators') + ";"
            csvContent += this.$t('forms.content.executives') + ";"
            csvContent += this.$t('forms.content.ept') + ";"
            csvContent += this.$t('forms.content.phone') + ";"
            csvContent += this.$t('forms.content.address') + ";"
            csvContent += this.$t('forms.content.zip') + ";"
            csvContent += this.$t('forms.content.city') + ";"
            csvContent += this.$t('forms.content.country') + ";"
            csvContent += "\r\n"

            // Convert object to CSV line
            filteredEmployees.forEach(function(e) {
                let employeeData = ""
                employeeData += e.gender[i18n.locale] + ";"
                employeeData += e.last_name + ";"
                employeeData += e.first_name + ";"
                employeeData += e.birthday + ";"
                employeeData += e.address.phone + ";"
                employeeData += e.address.address + ";"
                employeeData += e.address.zip + ";"
                employeeData += e.address.city + ";"
                employeeData += e.address.country.name + ";"
                csvContent += employeeData
                
                let first = true
                e.jobs.forEach(function(j) {
                    if (!first) {
                        csvContent += "\r\n" + employeeData
                    } else {
                        first = false
                    }

                    csvContent += j.job_name[i18n.locale] + ";"
                    if (j.main) {
                        csvContent += i18n.t('export.true') + ";"
                    } else {
                        csvContent += i18n.t('export.false') + ";"
                    }
                    csvContent += j.percentage + ";"
                    csvContent += j.email + ";"
                    csvContent += j.organization.name + ";"
                    csvContent += j.organization.canton[i18n.locale] + ";"
                    csvContent += j.organization.status[i18n.locale] + ";"
                    csvContent += j.organization.email + ";"
                    csvContent += j.organization.url + ";"
                    csvContent += j.organization.collaborators + ";"
                    csvContent += j.organization.executives + ";"
                    csvContent += j.organization.ept + ";"
                    csvContent += j.organization.address.phone + ";"
                    csvContent += j.organization.address.address + ";"
                    csvContent += j.organization.address.zip + ";"
                    csvContent += j.organization.address.city + ";"
                    csvContent += j.organization.address.country.name + ";"
                })

                csvContent += "\r\n"
            })

            // Replace undefined tags by blank spaces
            csvContent = csvContent.replaceAll("undefined", "")

            // Replace null tags by blank spaces
            csvContent = csvContent.replaceAll("null", "")
            
            // Download CSV content
            let link = window.document.createElement("a");
            link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent));
            link.setAttribute("download", "employees.csv");
            link.click();
        },

        cancel() {
            this.resetErrors()
            this.setEditingEmployee(false)
        },

        selectEmployee(id) {
            let employee = this.employees.filter(employee => employee.id == id)[0]
            this.employee = JSON.parse(JSON.stringify(employee))
            this.employee.jobs.forEach(job => {
                let organizationsMatching = this.organizations.filter(o => o.id == job.organization.id)
                if (organizationsMatching.length > 0) {
                    job.address = {
                        address: "",
                        city: "",
                        country_id: 216,
                        zip: ""
                    }
                }
            })
            this.setEditingEmployee(true)
        },

        setDeletingEmployee(id) {
            this.deletingEmployee = this.employees.filter(employee => employee.id == id)[0]
        },

        destroyEmployee() {
            this.deleteEmployee(this.deletingEmployee)
        },

        addJobDetails() {
            let job = JSON.parse(JSON.stringify(this.job))
            this.employee.jobs.push(job)
        },

        changePickedJob(id) {
            this.employee.jobs.forEach(job => {
                job.picked = id
            })
        },

        deleteJob(id) {
            // Delete job
            this.employee.jobs.splice(id, 1);

            // Reset picked main job as first if previous main job is deleted
            if (this.employee.jobs.length > 0 && this.employee.jobs[0].picked === id) {
                this.changePickedJob(0)
            }

            // Shift picked job if greater than deleted job
            if (this.employee.jobs.length > 0 && this.employee.jobs[0].picked > id) {
                this.changePickedJob(this.employee.jobs[0].picked - 1)
            }
        },

        submit() {

            // Reset any previous error
            this.resetErrors()

            // Prevent login if valdiation contains errors
            this.$v.$touch();
            if (this.$v.$pending || this.$v.$error) return

            // Reset validation otherwise
            this.$v.$reset()

            // Update employee
            this.editEmployee({ id: this.employee.id, employee: this.employee, reloadEmployees: true })
        }
    }
}

</script>

<style scoped>
    #pageContainer {
        padding: 50px 100px;
        background-color: white;
        margin: auto;
        margin-top: 56px;
    }
</style>