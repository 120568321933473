<template>
    <div id="pageContainer">

        <h2 class="unselectable title font-weight-bold mb-0">{{ $t('settings.title') }}</h2>

        <!-- Email change -->
        <b-card class="mt-3">

            <!-- Error banner -->
            <div v-if="errors.updateSettingsEmail" class="alert alert-danger text-center mt-0 mb-3" role="alert">{{ errors.updateSettingsEmail }}</div>

            <!-- Input and button -->
            <div class="form-row">

                <!-- Title -->
                <div class="form-group col-md-9 m-0">
                    <h5 class="mb-3">{{ $t('settings.email-title') }}</h5>
                </div>

                <!-- Email button -->
                <div class="form-group col-md-3 m-0">
                    <b-button size="sm" variant="info" class="w-100 h-100" @click="submitSettingsEmail()" :disabled="loading.editSettings">
                        <div v-if="!loading.editSettings" class="pointer m-0">{{ $t('account.apply') }}</div>
                        <half-circle-spinner v-else class="mx-auto"
                            :animation-duration="800"
                            :size="23"
                            :color="'#ffffff'"
                        />
                    </b-button>
                </div>

            </div>

            <label class="form-label mt-2 w-50">
                {{ $t('forms.content.email') }}
                <span v-if="!$v.email.required">*</span>
            </label>
            <input class="form-control" :class="{ 'is-invalid': validationStatus($v.email) }" v-model="email" type="text" />
            <div v-if="!$v.email.required" class="invalid-feedback">{{ $t('forms.validation.email') }}</div>
            <div v-if="!$v.email.email" class="invalid-feedback">{{ $t('forms.validation.email-format') }}</div>

        </b-card>

        <!-- Form change -->
        <b-card class="mt-3">

            <!-- Error banner -->
            <div v-if="errors.updateSettingsForm" class="alert alert-danger text-center mt-0 mb-3" role="alert">{{ errors.updateSettingsForm }}</div>

            <!-- Input and button -->
            <div class="form-row">

                <!-- Title -->
                <div class="form-group col-md-9 m-0">
                    <h5 class="mb-3">{{ $t('settings.form-title') }}</h5>
                </div>

                <!-- Form button -->
                <div class="form-group col-md-3 m-0">
                    <b-button size="sm" variant="info" class="w-100 h-100" @click="submitSettingsForm()" :disabled="loading.editSettings">
                        <div v-if="!loading.editSettings" class="pointer m-0">{{ $t('account.apply') }}</div>
                        <half-circle-spinner v-else class="mx-auto"
                            :animation-duration="800"
                            :size="23"
                            :color="'#ffffff'"
                        />
                    </b-button>
                </div>

            </div>

            <label class="form-label mt-2 w-50">
                {{ $t('forms.content.form') }}
                <span v-if="!$v.form.required">*</span>
            </label>
            <textarea rows="12" class="form-control" :class="{ 'is-invalid': validationStatus($v.form) }" v-model="form" type="text" />
            <div v-if="!$v.form.required" class="invalid-feedback">{{ $t('forms.validation.form') }}</div>

        </b-card>

        <!-- Help change -->
        <b-card class="mt-3">

            <!-- Error banner -->
            <div v-if="errors.updateSettingsForm" class="alert alert-danger text-center mt-0 mb-3" role="alert">{{ errors.updateSettingsForm }}</div>

            <!-- Input and button -->
            <div class="form-row">

                <!-- Title -->
                <div class="form-group col-md-9 m-0">
                    <h5 class="mb-3">{{ $t('settings.help-title') }}</h5>
                </div>

                <!-- Form button -->
                <div class="form-group col-md-3 m-0">
                    <b-button size="sm" variant="info" class="w-100 h-100" @click="submitSettingsHelp()" :disabled="loading.editSettings">
                        <div v-if="!loading.editSettings" class="pointer m-0">{{ $t('account.apply') }}</div>
                        <half-circle-spinner v-else class="mx-auto"
                            :animation-duration="800"
                            :size="23"
                            :color="'#ffffff'"
                        />
                    </b-button>
                </div>

            </div>

            <label class="form-label mt-2 w-50">
                {{ $t('forms.content.help') }}
                <span v-if="!$v.form.required">*</span>
            </label>
            <textarea rows="12" class="form-control" :class="{ 'is-invalid': validationStatus($v.help) }" v-model="help" type="text" />
            <div v-if="!$v.help.required" class="invalid-feedback">{{ $t('forms.validation.help') }}</div>

        </b-card>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { HalfCircleSpinner } from 'epic-spinners'
import { required, email } from 'vuelidate/lib/validators'

export default {
    components: {
        HalfCircleSpinner
    },
    data() {
        return {
            email: "",
            form: "",
            help: ""
        }
    },
    validations: {
        email: { required, email },
        form: { required },
        help: { required }
    },
    mounted() {
        this.loadSettings()
    },
    watch: {
        settings: function(newSettings) {
            newSettings.forEach(setting => {
                if (setting.name == "emailVerification") {
                    this.email = setting.value
                }
                if (setting.name == "registerForm") {
                    this.form = setting.value
                }
                if (setting.name == "registerHelp") {
                    this.help = setting.value
                }
            });
        }
    },
    computed: {
        ...mapState('app', [ 'settings', 'loading', 'errors' ]),
    },
    methods: {
        ...mapActions('app', [ 'loadSettings', 'editSettings', 'resetErrors' ]),

        validationStatus: function(validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },

        submitSettingsEmail: function() {
            // Reset errors in any case
            this.resetErrors();

            // Prevent login if valdiation contains errors
            this.$v.email.$touch();
            if (this.$v.email.$pending || this.$v.email.$error) return;

            // Reset validation otherwise
            this.$v.email.$reset();
            
            // Request
            this.editSettings({ name: "emailVerification", value: this.email });
        },

        submitSettingsForm: function() {
            // Reset errors in any case
            this.resetErrors();

            // Prevent login if valdiation contains errors
            this.$v.form.$touch();
            if (this.$v.form.$pending || this.$v.form.$error) return;

            // Reset validation otherwise
            this.$v.form.$reset();
            
            // Request
            this.editSettings({ name: "registerForm", value: this.form });
        },

        submitSettingsHelp: function() {
            // Reset errors in any case
            this.resetErrors();

            // Prevent login if valdiation contains errors
            this.$v.help.$touch();
            if (this.$v.help.$pending || this.$v.help.$error) return;

            // Reset validation otherwise
            this.$v.help.$reset();
            
            // Request
            this.editSettings({ name: "registerHelp", value: this.help });
        }
    }
}

</script>

<style scoped>
    #pageContainer {
        padding: 50px;
        background-color: white;
        max-width: 1000px;
        margin: auto;
        margin-top: 56px;
    }
</style>