<template>
    <div>

        <!-- Phone -->
        <label class="form-label mt-2 w-50">
            {{ $t('forms.content.phone') }}
            <span v-if="v.phone.required === false">*</span>
        </label>
        <input class="form-control" :class="{ 'is-invalid': validationStatus(v.phone) }" v-model="value.phone" type="tel" :disabled="disabled" />
        <div v-if="!v.phone.required" class="invalid-feedback">{{ $t('forms.validation.phone') }}</div>

        <!-- Address -->
        <label class="form-label mt-2 w-50">
            {{ $t('forms.content.address') }}
            <span v-if="v.address.required === false">*</span>
        </label>
        <input class="form-control" :class="{ 'is-invalid': validationStatus(v.address) }" v-model="value.address" type="text" :disabled="disabled" />
        <div v-if="!v.address.required" class="invalid-feedback">{{ $t('forms.validation.address') }}</div>
        
        <div class="form-row">
            <div class="form-group col-md-4 m-0">

                <!-- ZIP -->
                <label class="form-label mt-2">
                    {{ $t('forms.content.zip') }}
                    <span v-if="v.zip.required === false">*</span>
                </label>
                <input class="form-control" :class="{ 'is-invalid': validationStatus(v.zip) }" v-model="value.zip" type="text" :disabled="disabled" />
                <div v-if="!v.zip.required" class="invalid-feedback">{{ $t('forms.validation.zip') }}</div>

            </div>
            <div class="form-group col-md-8 m-0">

                <!-- City -->
                <label class="form-label mt-2">
                    {{ $t('forms.content.city') }}
                    <span v-if="v.city.required === false">*</span>
                </label>
                <input class="form-control" :class="{ 'is-invalid': validationStatus(v.city) }" v-model="value.city" type="text" :disabled="disabled" />
                <div v-if="!v.city.required" class="invalid-feedback">{{ $t('forms.validation.city') }}</div>

            </div>
        </div>

        <!-- Country -->
        <label class="form-label mt-2">
            {{ $t('forms.content.country') }}
            <span v-if="v.country.required === false">*</span>
        </label>
        <select v-if="countries" class="form-control" :class="{ 'is-invalid': validationStatus(v.country) }" v-model="value.country" :disabled="disabled">
            <option value="">{{ $t('forms.content.select') }}</option>
            <option v-for="country in countries" :key="country.name" :value="country">
                {{ country.name }}
            </option>
        </select>
        <div v-else class="form-control d-flex justify-content-center">
            <div class="spinner-border spinner-border-sm text-info mt-1" role="status"/>
        </div>
        <div v-if="!v.country.required" class="invalid-feedback">{{ $t('forms.validation.country') }}</div>
        
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        value: {
            type: Object,
            default: null
        },
        v: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState('auth', [ 'errorMessage', 'isLoading' ]),
        ...mapState('app', [ 'countries' ]),
    },
    methods: {
        ...mapActions('auth', [ 'resetErrors', 'login' ]),

        validationStatus: function(validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },
    }
}
</script>

<style scoped>

</style>