<template>
    <div class="mt-4">

        <h5 class="text-info">{{ $t('register.form.title-job') }}</h5>
        
        <div class="border rounded p-3" :class="{ 'border-info': value.picked == id && count > 1 }">
            
            <!-- Email -->
            <label class="form-label mt-2">
                {{ $t('forms.content.email') }}
                <span v-if="!v.email.required">*</span>
            </label>
            <input class="form-control" :class="{ 'is-invalid': validationStatus(v.email) }" v-model="value.email" type="text" />
            <div v-if="!v.email.required" class="invalid-feedback">{{ $t('forms.validation.email') }}</div>
            <div v-if="!v.email.email" class="invalid-feedback">{{ $t('forms.validation.email-format') }}</div>

            <div class="form-row mt-2">
                <div class="form-group col-sm-4 m-0">

                    <!-- Percentage -->
                    <label class="form-label">
                        {{ $t('forms.content.percentage') }}
                        <span v-if="!v.percentage.required">*</span>
                    </label>
                    <select class="form-control" :class="{ 'is-invalid': validationStatus(v.percentage) }" v-model="value.percentage">
                        <option value="">{{ $t('forms.content.select') }}</option>
                        <option v-for=" i in 10" :key="i">
                            {{ i * 10 + " %" }}
                        </option>
                        <option value="auxiliary">{{ $t('forms.content.auxiliary') }}</option>
                    </select>
                    <div v-if="!v.percentage.required" class="invalid-feedback">{{ $t('forms.validation.percentage') }}</div>

                </div>
                <div class="form-group col-sm-8 m-0">
       
                    <!-- Role -->
                    <label class="form-label">
                        {{ $t('forms.content.role') }}
                        <span v-if="!v.job_name.required">*</span>
                    </label>
                    <select v-if="jobs" class="form-control" :class="{ 'is-invalid': validationStatus(v.job_name) }" v-model="value.job_name">
                        <option value="">{{ $t('forms.content.select') }}</option>
                        <option v-for="job in jobs" :key="job.fr" :value="job">
                            {{ job[$i18n.locale] }}
                        </option>
                    </select>

                    <div v-else class="form-control d-flex justify-content-center">
                        <div class="spinner-border spinner-border-sm text-info mt-1" role="status"/>
                    </div>
                    <div v-if="!v.job_name.required" class="invalid-feedback">{{ $t('forms.validation.role') }}</div>

                </div>
            </div>

            <!-- Organization -->
            <label class="form-label mt-2">
                {{ $t('forms.content.organization') }}
                <span v-if="!v.organization.required">*</span>
            </label>
            <select v-if="organizations" class="form-control" :class="{ 'is-invalid': validationStatus(v.organization) }" v-model="value.organization">
                <option value="">{{ $t('forms.content.select') }}</option>
                <option v-for="organization in orderedOrganizations" :key="organization.name" :value="organization">
                    {{ organization.name }}
                </option>
            </select>
            <div v-else class="form-control d-flex justify-content-center">
                <div class="spinner-border spinner-border-sm text-info mt-1" role="status"/>
            </div>
            <div v-if="!v.organization.required" class="invalid-feedback">{{ $t('forms.validation.organization') }}</div>

            <div v-if="count > 1" class="form-row mt-3">
                <div class="form-group col-sm-8 m-0">

                    <!-- Main job -->
                    <div class="form-check mt-2">
                        <input class="form-check-input" type="radio" :value="id" :id="'customRadio' + id" v-model="value.picked" v-on:change="$emit('change', id)">
                        <label class="form-check-label unselectable" :for="'customRadio' + id">{{ $t('register.form.defined-main-job') }}</label>
                    </div>

                </div>
                <div class="form-group col-sm-4 m-0">

                    <!-- Delete -->
                    <button class="btn btn-secondary float-right font-weight-bold rounded" type="button" v-on:click="$emit('delete', id)">
                        <i class="fas fa-trash fa-lg mt-1 mr-2"></i>
                        {{ $t('register.form.delete-job-button') }}
                    </button>

                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AddressForm from './AddressForm.vue'

export default {
    components: {
        AddressForm
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        v: {
            type: Object,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        count: {
            type: Number,
            required: true
        },
    },
    computed: {
        ...mapState('auth', [ 'errorMessage', 'isLoading' ]),
        ...mapState('app', [ 'jobs', 'organizations' ]),

        orderedOrganizations() {
            if (this.organizations) {
                return [...this.organizations].sort((a, b) => (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0)
            }

            return []
        },
    },
    methods: {
        ...mapActions('auth', [ 'resetErrors', 'login' ]),

        validationStatus: function(validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        }
    }
}
</script>

<style scoped>

</style>