<template>
    <div id="background">

        <!-- Title -->
        <h2 class="unselectable title font-weight-bold mb-0">{{ $t('profile.title') }}</h2>

        <div v-if="employee">

            <!-- Employee Form -->
            <EmployeeDetailsForm v-model="employee" :v="$v.employee" />

            <!-- Job Form -->
            <JobDetailsForm v-for="(job, i) in employee.jobs" :key="i + renderShift"
                :id="i" 
                :count="employee.jobs.length"
                :value="job"
                :v="$v.employee.jobs.$each[i]"
                v-on:change="changePickedJob"
                v-on:delete="deleteJob" />

            <!-- Add job form button -->
            <button @click="addJobDetails()" class="btn btn-info w-100 my-4 font-weight-bold" :disabled="employee.jobs.length >= 4" type="button">
                <div v-if="employee.jobs.length < 4">
                    <i class="fas fa-plus fa-lg mt-1 mr-2"></i>
                    {{ $t('register.form.add-job-button') }}
                </div>
                <div v-else>{{ $t("register.form.add-job-button-limit") }}</div>
            </button>

            <!-- Error banner -->
            <div v-if="errors.register" class="alert alert-danger text-center my-2" role="alert">{{ errors.register }}</div>

            <!-- Cancel or register -->
            <div class="form-row">
                <div class="form-group col-sm-6 m-0">

                    <!-- Cancel -->
                    <button @click="reset()" class="btn btn-danger w-100 m-0 font-weight-bold" type="button">{{ $t("profile.reset") }}</button>

                </div>
                <div class="form-group col-sm-6 m-0">

                    <!-- Register -->
                    <button button @click="submit" class="btn btn-info w-100 m-0 font-weight-bold" type="button" :disabled="isLoading">
                        <div v-if="!isLoading" class="pointer m-0">{{ $t("profile.save") }}</div>
                        <half-circle-spinner v-else class="mx-auto"
                            :animation-duration="800"
                            :size="23"
                            :color="'#ffffff'"
                        />
                    </button>
                </div>
                
            </div>

        </div>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { HalfCircleSpinner } from 'epic-spinners'
import EmployeeDetailsForm from '../Forms/EmployeeDetailsForm'
import JobDetailsForm from '../Forms/JobDetailsForm'

export default {
    components: {
        HalfCircleSpinner,
        EmployeeDetailsForm,
        JobDetailsForm
    },
    data() {
        return {
            checked: false,
            creation: false,
            employee: null,
            renderShift: 0,
            job: {
                picked: 0,
                email: "",
                percentage: "",
                organization: "",
                job_name: ""
            },
        }
    },
    validations: {
        employee: {
            first_name: { required },
            last_name: { required },
            gender: { required },
            birthday: { required },
            address: {
                phone: { },
                address: { required },
                zip: { required },
                city: { required },
                country: { required }
            },
            jobs: {
                $each: {
                    email: { required, email },
                    percentage: { required },
                    organization: { required },
                    job_name: { required }
                }
            }
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        ...mapState('auth', [ 'user' ]),
        ...mapState('app', [ 'errors', 'isLoading' ])
    },
    methods: {
        ...mapActions('app', [ 'resetErrors', 'editEmployee' ]),

        init: function() {
            this.employee = JSON.parse(JSON.stringify(this.user.employee))
        },

        reset: function() {
            this.$router.go(-1)
        },

        validationStatus: function(validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },

        addJobDetails: function() {
            let job = JSON.parse(JSON.stringify(this.job))
            this.employee.jobs.push(job)
        },

        changePickedJob: function(id) {
            this.employee.jobs.forEach(job => {
                job.picked = id
            })

            // Reload all job forms to update picked view
            this.renderShift += this.employee.jobs.length
        },

        deleteJob: function (id) {
            // Delete job
            this.employee.jobs.splice(id, 1);

            // Reset picked main job as first if previous main job is deleted
            if (this.employee.jobs.length > 0 && this.employee.jobs[0].picked === id) {
                this.changePickedJob(0)
            }
        },

        submit: function() {
            // Reset errors in any case
            this.resetErrors();

            // Prevent login if valdiation contains errors
            this.$v.$touch();
            if (this.$v.$pending || this.$v.$error) return;

            // Reset validation otherwise
            this.$v.$reset();

            // Validate edit
            if (confirm(this.$i18n.t("profile.confirm"))) {
                this.editEmployee({ id: this.employee.id, employee: this.employee, reloadEmployees: false })
            }   
        }
    }
}
</script>

<style scoped>
    #background {
        padding: 50px;
        background-color: white;
        max-width: 1000px;
        margin: auto;
        margin-top: 56px;
    }
</style>