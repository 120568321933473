<template>
    <div id="app">
        <NavBar />
        <div id="content">
            <router-view class="PageContent" name="PageContent"></router-view>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import NavBar from './NavBar.vue'

export default {
    components: {
        NavBar
    },
    mounted() {
        this.initLocale()
        this.getFormData()
    },
    methods: 
        {...mapActions('app', [ 'getFormData' ]),

        initLocale: function() {
            let lastLocale = localStorage.getItem('lang')
            if (lastLocale != undefined) {
                this.$i18n.locale = lastLocale
            }
        }
    }
}
</script>

<style scoped>
    #content {
        margin-top: 56px;
    }
</style>