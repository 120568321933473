<template>
    <div id="pageContainer">

        <!-- Account title -->
        <h2 class="unselectable title font-weight-bold mb-0">{{ $t('first-use.title') }}</h2>

        <!-- Description -->
        <label class="form-label mt-2">{{ $t('first-use.description') }}</label>

        <!-- Password change -->
        <b-card class="mt-2">

            <!-- Error banner -->
            <div v-if="errors.updatePassword" class="alert alert-danger text-center mt-0 mb-3" role="alert">{{ errors.updatePassword }}</div>

            <div class="form-row">

                <!-- Title -->
                <div class="form-group col-md-9 m-0">
                    <h5 class="mb-3">{{ $t('account.password-title') }}</h5>
                </div>

                <!-- Password button -->
                <div class="form-group col-md-3 m-0">
                    <b-button size="sm" variant="info" class="w-100 h-100" @click="submitPassword()" :disabled="loading.updatePassword">
                        <div v-if="!loading.updatePassword" class="pointer m-0">{{ $t('account.apply') }}</div>
                        <half-circle-spinner v-else class="mx-auto"
                            :animation-duration="800"
                            :size="23"
                            :color="'#ffffff'"
                        />
                    </b-button>
                </div>

            </div>

            <!-- Current password -->
            <label class="form-label mt-2 w-50">
                {{ $t('forms.content.current-password') }}
                <span v-if="$v.currentPassword.required === false">*</span>
            </label>
            <div class="input-group">
                <input v-if="showCurrent" class="form-control" :class="{ 'is-invalid': validationStatus($v.currentPassword) }" v-model="currentPassword" type="text" />
                <input v-else class="form-control" :class="{ 'is-invalid': validationStatus($v.currentPassword) }" v-model="currentPassword" type="password" />
                <div class="input-group-append" style="cursor: pointer;" @click="showCurrent = !showCurrent">
                    <div class="input-group-text">
                        <i class="fas fa-eye" id="togglePassword"></i>
                    </div>
                </div>
                <div v-if="!$v.currentPassword.required" class="invalid-feedback">{{ $t('forms.validation.current-password') }}</div>
            </div>
            
            <!-- New password -->
            <label class="form-label mt-2 w-50">
                {{ $t('forms.content.new-password') }}
                <span v-if="$v.newPassword.required === false">*</span>
            </label>
            <div class="input-group">
                <input v-if="showNew" class="form-control" :class="{ 'is-invalid': validationStatus($v.newPassword) }" v-model="newPassword" type="text" />
                <input v-else class="form-control" :class="{ 'is-invalid': validationStatus($v.newPassword) }" v-model="newPassword" type="password" />
                <div class="input-group-append" style="cursor: pointer;" @click="showNew = !showNew">
                    <div class="input-group-text">
                        <i class="fas fa-eye" id="togglePassword"></i>
                    </div>
                </div>
                <div v-if="!$v.newPassword.required" class="invalid-feedback">{{ $t('forms.validation.new-password') }}</div>
                <div v-else-if="!$v.newPassword.minLength" class="invalid-feedback">{{ $t('forms.validation.password-length') }}</div>
            </div>

            <!-- New password confirmation -->
            <label class="form-label mt-2 w-50">
                {{ $t('forms.content.new-password-confirmation') }}
                <span v-if="$v.newPasswordConfirmation.required === false">*</span>
            </label>
            <div class="input-group">
                <input v-if="showConfirmation" class="form-control" :class="{ 'is-invalid': validationStatus($v.newPasswordConfirmation) }" v-model="newPasswordConfirmation" type="text" />
                <input v-else class="form-control" :class="{ 'is-invalid': validationStatus($v.newPasswordConfirmation) }" v-model="newPasswordConfirmation" type="password" />
                <div class="input-group-append" style="cursor: pointer;" @click="showConfirmation = !showConfirmation">
                    <div class="input-group-text">
                        <i class="fas fa-eye" id="togglePassword"></i>
                    </div>
                </div>
                <div v-if="!$v.newPasswordConfirmation.required" class="invalid-feedback">{{ $t('forms.validation.new-password-confirmation') }}</div>
                <div v-else-if="!$v.newPasswordConfirmation.sameAs" class="invalid-feedback">{{ $t('forms.validation.password-match') }}</div>
            </div>
        
        </b-card>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { HalfCircleSpinner } from 'epic-spinners'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
    components: {
        HalfCircleSpinner
    },
    data() {
        return {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirmation: "",
            showCurrent: false,
            showNew: false,
            showConfirmation: false,
        }
    },
    validations: {
        currentPassword: { required },
        newPassword: { 
            required, 
            minLength: minLength(6) 
        },
        newPasswordConfirmation: { 
            required, 
            sameAs: sameAs('newPassword') 
        }
    },
    computed: {
        ...mapState('auth', [ 'user', 'loading', 'errors' ]),
    },
    methods: {
        ...mapActions('auth', [ 'updatePassword', 'resetErrors' ]),

        validationStatus: function(validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },

        submitPassword: function() {
            // Reset errors in any case
            this.resetErrors();

            // Prevent login if valdiation contains errors
            this.$v.currentPassword.$touch();
            this.$v.newPassword.$touch();
            this.$v.newPasswordConfirmation.$touch();

            if (this.$v.currentPassword.$pending || this.$v.currentPassword.$error) return;
            if (this.$v.newPassword.$pending || this.$v.newPassword.$error) return;
            if (this.$v.newPasswordConfirmation.$pending || this.$v.newPasswordConfirmation.$error) return;

            // Reset validation otherwise
            this.$v.currentPassword.$reset();
            this.$v.newPassword.$reset();
            this.$v.newPasswordConfirmation.$reset();

            let infos = { 
                currentPassword: this.currentPassword, 
                newPassword: this.newPassword,
                newPasswordConfirmation: this.newPasswordConfirmation
            }
            
            // Try to login
            this.updatePassword({ id: this.user.id, infos: infos });
        }
    }
}

</script>

<style scoped>
    #pageContainer {
        padding: 50px;
        background-color: white;
        max-width: 1000px;
        margin: auto;
        margin-top: 56px;
    }
</style>