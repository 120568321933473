import AuthService from '../services/AuthService';
import EmployeesService from '../services/EmployeesService';
import OrganizationsService from '../services/OrganizationsService';
import DocumentsService from '../services/DocumentsService';
import SettingsService from '../services/SettingsService';

import { i18n } from '../app.js' 
import router from '../router'

const state = { 
    editingOrganization: false,
    editingEmployee: false,
    employees: [],
    documents: [],
    settings: [],
    errors: {
        employees: "",
        deleteEmployee: "",
        editEmployee: "",
        organizations: "",
        deleteOrganization: "",
        addOrganization: "",
        editOrganization: "",
        addDocument: "",
        documents: "",
        deleteDocument: "",
        downloadDocument: "",
        form_data: "",
        settings: "",
        editSettings: ""
    },
    loading: {
        employees: false,
        deleteEmployee: false,
        editEmployee: false,
        organizations: false,
        deleteOrganization: false,
        addOrganization: false,
        editOrganization: false,
        addDocument: false,
        documents: false,
        deleteDocument: false,
        downloadDocument: false,
        form_data: false,
        settings: false,
        editSettings: false
    },
    jobs: null,
    countries: null,
    organizations: null,
    cantons: null,
    status: null,
    genders: null
}

const getters = { 
    
}

const mutations = { 

    // Errors
    reset_errors: (state) => {
        for (const error in state.errors) {
            state.errors[error] = "";
        }
    },

    reset_editings: (state) => {
        state.editingEmployee = false
        state.editingOrganization = false
    },

    // Request state handler
    request: (state, operation) => {
        state.errors[operation] = ""
        state.loading[operation] = true
    },
    success: (state, operation) => {
        state.loading[operation] = false
    },
    error: (state, { operation, error }) => {
        state.errors[operation] = error
        state.loading[operation] = false
    },

    // Employees
    employees: (state, employees) => {
        state.employees = employees
    },

    set_editing_employee: (state, value) => {
        state.editingEmployee = value
    },

    // Organizations
    organizations: (state, organizations) => {
        state.organizations = organizations
    },

    set_editing_organization: (state, value) => {
        state.editingOrganization = value
    },

    // Documents
    documents: (state, documents) => {
        state.documents = documents
    },

    // Settings
    settings: (state, settings) => {
        state.settings = settings
    },

    // Form data
    form_data: (state, data) => {
        state.countries = data.countries
        state.jobs = data.jobs
        state.organizations = data.organizations
        state.cantons = data.cantons
        state.status = data.status
        state.genders = data.genders
        state.isLoading = false
    },
}

const actions = { 

    // ----------- Errors -----------

    resetErrors({ commit }) {
        commit('reset_errors')
    },

    resetEditings({ commit }) {
        commit('reset_editings')
    },

    // ----------- Settings -----------

    loadSettings({ commit }) {
        commit('request', 'settings')
        SettingsService.settings()
            .then(response => {
                commit('success', 'settings')
                commit('settings', response.data)
            })
            .catch(_ => {
                commit('error', { operation: 'settings', error: i18n.t("") })
            })
    },

    editSettings({ commit }, { name, value }) {
        commit('request', 'editSettings')
        SettingsService.editSettings(name, value)
            .then(_ => {
                commit('success', 'editSettings')
            })
            .catch(_ => {
                commit('error', { operation: 'editSettings', error: i18n.t("") })
            })
    },

    // ----------- Employees -----------

    setEditingEmployee({ commit }, value) {
        commit('set_editing_employee', value)
    },

    loadEmployees({ commit }) {
        commit('request', 'employees')
        EmployeesService.employees()
            .then(response => {
                commit('success', 'employees')
                commit('employees', response.data)
            })
            .catch(_ => {
                commit('error', { operation: 'employees', error: i18n.t("") })
            })
    },

    deleteEmployee({ commit, dispatch }, employee) {
        commit('request', 'deleteEmployee')
        EmployeesService.deleteEmployee(employee.id)
            .then(_ => {
                commit('success', 'deleteEmployee')
                dispatch('loadEmployees')
            })
            .catch(_ => {
                commit('error', { operation: 'deleteEmployee', error: i18n.t("") })
            })
    },

    editEmployee({ commit, dispatch }, { id, employee, reloadEmployees }) {
        commit('request', 'editEmployee')
        EmployeesService.editEmployee(id, employee)
            .then(_ => {
                commit('success', 'editEmployee')
                commit('set_editing_employee', false)
                
                if (reloadEmployees) {
                    dispatch('loadEmployees')
                } else {
                    localStorage.setItem('loggedIn', "false")
                    localStorage.removeItem('user')
                    
                    commit('auth/logout', '', { root: true })
                    router.push('/login')
                }
            })
            .catch(error => {
                switch (error.response.status) {
                    default:
                        commit('error', { operation: 'editEmployee', error: i18n.t("login.errors.default", { status: error.response.status }) })
                        break
                }
            })
    },

    // ----------- Organizations -----------

    setEditingOrganization({ commit }, value) {
        commit('set_editing_organization', value)
    },

    deleteOrganization({ commit, dispatch }, organization) {
        commit('request', 'deleteOrganization')
        OrganizationsService.deleteOrganization(organization.id)
            .then(_ => {
                commit('success', 'deleteOrganization')
                dispatch('loadOrganizations')
            })
            .catch(error => {
                switch (error.response.status) {
                    case 409:
                        commit('success', 'deleteOrganization')
                        alert(i18n.t("organizations.delete-error", { jobs: error.response.data }));
                        break
                    default:
                        break
                }
            })
    },

    addOrganization({ commit, dispatch }, organization) {
        commit('request', 'addOrganization')
        OrganizationsService.addOrganization(organization)
            .then(_ => {
                commit('success', 'addOrganization')
                commit('set_editing_organization', false)
                dispatch('loadOrganizations')
            })
            .catch(error => {
                switch (error.response.status) {
                    case 409:
                        commit('error', { operation: 'addOrganization', error: i18n.t("forms.errors.duplicate-organization") })
                        break
                    default:
                        commit('error', { operation: 'addOrganization', error: i18n.t("login.errors.default", { status: error.response.status }) })
                        break
                }
            })
    },

    editOrganization({ commit, dispatch }, { id, organization }) {
        commit('request', 'editOrganization')
        OrganizationsService.editOrganization(id, organization)
            .then(_ => {
                commit('success', 'editOrganization')
                commit('set_editing_organization', false)
                dispatch('loadOrganizations')
            })
            .catch(error => {
                switch (error.response.status) {
                    default:
                        commit('error', { operation: 'editOrganization', error: i18n.t("login.errors.default", { status: error.response.status }) })
                        break
                }
            })
    },

    loadOrganizations({ commit }) {
        commit('request', 'organizations')
        OrganizationsService.organizations()
            .then(response => {
                commit('success', 'organizations')
                commit('organizations', response.data)
            })
            .catch(_ => {
                commit('error', { operation: 'organizations', error: i18n.t("") })
            })
    },

    // ----------- Documents -----------

    addDocument({ commit, dispatch }, { doc, filters, folder }) {
        commit('request', 'addDocument')
        DocumentsService.addDocument(doc, filters, folder)
            .then(_ => {
                commit('success', 'addDocument')
                dispatch('loadDocuments')
            })
            .catch(_ => {
                commit('error', { operation: 'addDocument', error: i18n.t("") })
            })
    },

    loadDocuments({ commit }) {
        commit('request', 'documents')
        DocumentsService.documents()
            .then(response => {
                commit('success', 'documents')
                commit('documents', response.data)
            })
            .catch(_ => {
                commit('error', { operation: 'documents', error: i18n.t("") })
            })
    },

    deleteDocument({ commit, dispatch }, docName) {
        commit('request', 'deleteDocument')
        DocumentsService.deleteDocument(docName)
            .then(_ => {
                commit('success', 'deleteDocument')
                dispatch('loadDocuments')
            })
            .catch(_ => {
                commit('error', { operation: 'deleteDocument', error: i18n.t("") })
            })
    },

    downloadDocument({ commit }, docName) {
        commit('request', 'downloadDocument')
        DocumentsService.downloadDocument(docName)
            .then(response => {
                commit('success', 'downloadDocument')

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', docName);
                document.body.appendChild(link);
                link.click();
            })
            .catch(_ => {
                commit('error', { operation: 'downloadDocument', error: i18n.t("") })
            })
    },

    // ----------- Form data -----------

    getFormData({ commit }) {
        commit('request', 'form_data')
        AuthService.employeeFormData()
            .then(response => {
                commit('success', 'form_data')
                commit('form_data', response.data)
            })
            .catch(error => {
                commit('error', { operation: 'form_data', error: i18n.t("login.errors.default", { status: error.response.status }) })
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}  