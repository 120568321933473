<template>
    <div id="pageContainer">

        <h2 class="unselectable title font-weight-bold mb-0">{{ $t('documents.title') }}</h2>

        <!-- Document upload card -->
        <b-card v-if="isAdmin" class="mt-3">

            <!-- Title -->
            <h5 class="mb-3">{{ $t('documents.add-title') }}</h5>

            <!-- File form -->
            <b-form-file
                v-model="file"
                :state="Boolean(file)"
                :placeholder="$t('documents.add-placeholder')"
                accept=".pdf"
                drop-placeholder="Drop file here..."
            />

            <b-row class="my-3" v-if="file">

                <!-- Filtering title -->
                <b-col>
                    <h5 class="m-0">{{ $t('documents.filter-title') }}</h5>
                </b-col>

                <!-- Clear filters button -->
                <b-col>
                    <div class="text-right">
                        <button type="button" class="btn btn-info" @click="clearFilters()">
                            <i class="fas fa-sync mr-1" />
                            {{ $t('employees.clear-filter') }}
                        </button>
                    </div>
                </b-col>

            </b-row>

            <!-- Filters -->
            <b-container class="mx-0 mw-100" v-if="file">

                <!-- By organization -->
                <b-row class="mb-2" align-v="center">
                    <b-col lg class="pr-1 pl-0">{{ $t('documents.filters.organization') }}</b-col>
                    <b-col lg class="px-1">
                        <select class="form-control" v-model="filtersOrganization[0]">
                            <option value="">{{ $t('forms.content.select') }}</option>
                            <option v-for="organization in orderedOrganizations" :key="organization.name" :value="organization.id">
                                {{ organization.name }}
                            </option>
                        </select>
                    </b-col>
                    <b-col lg class="px-1">
                        <select class="form-control" v-model="filtersOrganization[1]">
                            <option value="">{{ $t('forms.content.select') }}</option>
                            <option v-for="organization in orderedOrganizations" :key="organization.name" :value="organization.id">
                                {{ organization.name }}
                            </option>
                        </select>
                    </b-col>
                    <b-col lg class="px-1">
                        <select class="form-control" v-model="filtersOrganization[2]">
                            <option value="">{{ $t('forms.content.select') }}</option>
                            <option v-for="organization in orderedOrganizations" :key="organization.name" :value="organization.id">
                                {{ organization.name }}
                            </option>
                        </select>
                    </b-col>
                    <b-col lg class="pl-1 pr-0">
                        <select class="form-control" v-model="filtersOrganization[3]">
                            <option value="">{{ $t('forms.content.select') }}</option>
                            <option v-for="organization in orderedOrganizations" :key="organization.name" :value="organization.id">
                                {{ organization.name }}
                            </option>
                        </select>
                    </b-col>
                </b-row>

                <!-- By canton -->
                <b-row class="mb-2" align-v="center">
                    <b-col lg class="pr-1 pl-0">{{ $t('documents.filters.canton') }}</b-col>
                    <b-col lg class="px-1">
                        <select class="form-control" v-model="filtersCanton[0]">
                            <option value="">{{ $t('forms.content.select') }}</option>
                            <option v-for="canton in orderedCantons" :key="canton.fr" :value="canton.id">
                                {{ canton[$i18n.locale] }}
                            </option>
                        </select>
                    </b-col>
                    <b-col lg class="px-1">
                        <select class="form-control" v-model="filtersCanton[1]">
                            <option value="">{{ $t('forms.content.select') }}</option>
                            <option v-for="canton in orderedCantons" :key="canton.fr" :value="canton.id">
                                {{ canton[$i18n.locale] }}
                            </option>
                        </select>
                    </b-col>
                    <b-col lg class="px-1">
                        <select class="form-control" v-model="filtersCanton[2]">
                            <option value="">{{ $t('forms.content.select') }}</option>
                            <option v-for="canton in orderedCantons" :key="canton.fr" :value="canton.id">
                                {{ canton[$i18n.locale] }}
                            </option>
                        </select>
                    </b-col>
                    <b-col lg class="pl-1 pr-0">
                        <select class="form-control" v-model="filtersCanton[3]">
                            <option value="">{{ $t('forms.content.select') }}</option>
                            <option v-for="canton in orderedCantons" :key="canton.fr" :value="canton.id">
                                {{ canton[$i18n.locale] }}
                            </option>
                        </select>
                    </b-col>
                </b-row>

                <!-- By main job -->
                <b-row class="mb-2" align-v="center">
                    <b-col lg class="pr-1 pl-0">{{ $t('documents.filters.main-job') }}</b-col>
                    <b-col lg class="px-1">
                        <select class="form-control" v-model="filtersJob[0]">
                            <option value="">{{ $t('forms.content.select') }}</option>
                            <option v-for="job in orderedJobs" :key="job.fr" :value="job.id">
                                {{ job[$i18n.locale] }}
                            </option>
                        </select>
                    </b-col>
                    <b-col lg class="px-1">
                        <select class="form-control" v-model="filtersJob[1]">
                            <option value="">{{ $t('forms.content.select') }}</option>
                            <option v-for="job in orderedJobs" :key="job.fr" :value="job.id">
                                {{ job[$i18n.locale] }}
                            </option>
                        </select>
                    </b-col>
                    <b-col lg class="px-1">
                        <select class="form-control" v-model="filtersJob[2]">
                            <option value="">{{ $t('forms.content.select') }}</option>
                            <option v-for="job in orderedJobs" :key="job.fr" :value="job.id">
                                {{ job[$i18n.locale] }}
                            </option>
                        </select>
                    </b-col>
                    <b-col lg class="pl-1 pr-0">
                        <select class="form-control" v-model="filtersJob[3]">
                            <option value="">{{ $t('forms.content.select') }}</option>
                            <option v-for="job in orderedJobs" :key="job.fr" :value="job.id">
                                {{ job[$i18n.locale] }}
                            </option>
                        </select>
                    </b-col>
                </b-row>

                <!-- Folder name -->
                <b-row align-v="center">
                    <b-col class="p-0">
                        <b-form-input type="text" v-model="folder" placeholder="Nom du dossier" />
                    </b-col>
                </b-row>

            </b-container>

            <!-- Save button -->
            <button button @click="submit" class="btn btn-info mt-2 w-100" type="button" :disabled="!file || loading.addDocument">
                <div v-if="!loading.addDocument" class="pointer m-0">{{ $t('documents.save') }}</div>
                <half-circle-spinner v-else class="mx-auto"
                    :animation-duration="800"
                    :size="23"
                    :color="'#ffffff'"
                />
            </button>
        </b-card>

        <!-- Documents card -->
        <b-card class="mt-3">

            <!-- Title -->
            <h5 class="mb-3">{{ $t('documents.documents-title') }}</h5>

            <!-- Search bar -->
            <b-input-group>
                <b-form-input id="filter-input" v-model="filter" type="search" :placeholder="$t('employees.search')" />
                    <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">{{ $t('employees.clear') }}</b-button>
                </b-input-group-append>
            </b-input-group>

            <div class="overflow-auto mt-3">

                <!-- Deletion modal -->
                <Modal id="deletionModal"
                    :title="$t('documents.delete-modal-title')"
                    :cancelMessage="$t('documents.delete-modal-cancel')"
                    :confirmMessage="$t('documents.delete-modal-confirm')"
                    v-on:confirm="destroyDocument">
                    
                    <label v-if="deletingDocument" v-text="$t('forms.validation.confirm-delete', { content: deletingDocument.replace('documents/', '') })" />
                
                </Modal>

                <!-- Table -->
                <b-table v-if="mappedDocuments.length > 0" class="mb-0"
                    id="my-table"
                    :items="mappedDocuments"
                    :fields="fields"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :busy="loading.documents"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :borderless="true"
                    :hover="true"
                    :tbody-tr-class="rowClass"
                    small
                    sort-icon-left
                >
                    <template #table-busy>
                        <div class="text-center text-info my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>

                    <template #cell(actions)="row">

                        <!-- Expand button -->
                        <button v-if="row.item.documents" type="button" @click="row.toggleDetails" class="btn btn-info">
                            <i v-if="row.detailsShowing" class="fas fa-angle-up mr-1"></i>
                            <i v-else class="fas fa-angle-down mr-1"></i>
                            {{ row.detailsShowing ? $t('documents.close') : $t('documents.open') }}
                        </button>

                        <!-- Download button -->
                        <button v-else type="button" class="btn btn-info" @click="downloadDocument(path(row.item.name))" :disabled="loading.downloadDocument">
                            <i class="fas fa-file-download mr-1" v-if="!loading.downloadDocument" />
                            <label style="cursor: pointer;" v-if="!loading.downloadDocument" class="m-0">{{ $t('documents.download') }}</label>
                            <half-circle-spinner v-else class="mx-auto"
                                :animation-duration="800"
                                :size="23"
                                :color="'#ffffff'"
                            />
                        </button>

                        <!-- Delete button -->
                        <button v-if="isAdmin && !row.item.documents" type="button" class="btn btn-danger" data-toggle="modal" data-target="#deletionModal" @click="setDeletingEmployee(path(row.item.name))" :disabled="loading.deleteDocument">
                            <i class="fas fa-times mr-1" v-if="!loading.deleteDocument" />
                            <label style="cursor: pointer;" v-if="!loading.deleteDocument" class="m-0">{{ $t('documents.delete') }}</label>
                            <half-circle-spinner v-else class="mx-auto"
                                :animation-duration="800"
                                :size="23"
                                :color="'#ffffff'"
                            />
                        </button>

                    </template>

                    <template #row-details="row">
                        <b-table v-if="mappedDocuments.length > 0" class="mb-0"
                            id="my-table"
                            :items="row.item.documents"
                            :fields="fields"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            :busy="loading.documents"
                            :borderless="true"
                            :hover="true"
                            small
                            sort-icon-left
                        >

                            <template #cell(actions)="row">

                                <!-- Download button -->
                                <button type="button" class="btn btn-info" @click="downloadDocument(path(row.item.name))" :disabled="loading.downloadDocument">
                                    <i class="fas fa-file-download mr-1" v-if="!loading.downloadDocument" />
                                    <label style="cursor: pointer;" v-if="!loading.downloadDocument" class="m-0">{{ $t('documents.download') }}</label>
                                    <half-circle-spinner v-else class="mx-auto"
                                        :animation-duration="800"
                                        :size="23"
                                        :color="'#ffffff'"
                                    />
                                </button>

                                <!-- Delete button -->
                                <button v-if="isAdmin" type="button" class="btn btn-danger" data-toggle="modal" data-target="#deletionModal" @click="setDeletingEmployee(path(row.item.name))" :disabled="loading.deleteDocument">
                                    <i class="fas fa-times mr-1" v-if="!loading.deleteDocument" />
                                    <label style="cursor: pointer;" v-if="!loading.deleteDocument" class="m-0">{{ $t('documents.delete') }}</label>
                                    <half-circle-spinner v-else class="mx-auto"
                                        :animation-duration="800"
                                        :size="23"
                                        :color="'#ffffff'"
                                    />
                                </button>

                            </template>

                        </b-table>

                    </template>

                </b-table>

                <!-- Empty content -->
                <div v-else class="alert alert-secondary text-center m-0" role="alert">
                    <i class="fas fa-exclamation fa-lg mt-1 mr-2"/>
                    {{ $t('documents.empty') }}
                </div>

                <!-- Pagination -->
                <b-pagination class="mt-4" v-if="mappedDocuments.length > perPage"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    align="center"
                    aria-controls="my-table"
                >
                    <template #page="{ page }">
                        <b>{{ page }}</b>
                    </template>
                </b-pagination>
            </div>

        </b-card>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { HalfCircleSpinner } from 'epic-spinners'
import Modal from './Forms/Modal'

export default {
    components: {
        HalfCircleSpinner,
        Modal
    },
    data() {
        return {
            file: null,
            folder: "",
            deletingDocument: null,
            perPage: 10,
            currentPage: 1,
            filter: null,
            filterOn: [],
            filtersOrganization: [ '', '', '', '' ],
            filtersCanton: [ '', '', '', '' ],
            filtersJob: [ '', '', '', '' ]
        }
    },
    mounted() {
        this.loadDocuments()
    },
    computed: {
        ...mapState('app', [ 'documents', 'loading', 'errors', 'organizations', 'cantons', 'jobs' ]),
        ...mapGetters('auth', [ 'isAdmin' ]),

        fields() {
            // Default fields
            let fields = [
                { key: 'name', label: this.$t('documents.filters.name'), tdClass: 'align-middle p-2', sortable: true },
                { key: 'size', label: this.$t('documents.filters.size'), tdClass: 'align-middle text-nowrap p-2', sortable: true }
            ]

            // Filters for admins
            if (this.isAdmin)
            {
                fields.push({ key: 'organizations', label: this.$t('documents.filters.organization'), tdClass: 'align-middle p-2', sortable: true })
                fields.push({ key: 'cantons', label: this.$t('documents.filters.canton'), tdClass: 'align-middle p-2', sortable: true })
                fields.push({ key: 'jobNames', label: this.$t('documents.filters.main-job'), tdClass: 'align-middle p-2', sortable: true })
            }

            // Actions
            fields.push({ key: 'actions', label: this.$t('documents.filters.actions'), thClass: 'text-right', tdClass: 'align-middle text-right text-nowrap p-2' })

            return fields
        },

        rows() {
            return this.mappedDocuments.length
        },

        mappedDocuments() {
            if (this.documents) {

                // Regroup files in folders
                let folders = {}
                this.documents.forEach(document => {
                    let folderName = this.getFolder(document.name)

                    // Create folder if first file in it
                    if (folders[folderName] == undefined) {
                        folders[folderName] = []
                    }

                    // Add file in folder
                    folders[folderName].push(document)
                })

                // Map folders and files into table rows
                let ctx = this
                let mappedDocuments = []
                for (const [key, value] of Object.entries(folders)) {
                    if (key === "") {
                        value.forEach(document => {
                            mappedDocuments.push({
                                name: ctx.localName(document.name),
                                size: document.size,
                                organizations: !this.isAdmin || document.organizations.length == 0 ? "-" : document.organizations.map(o => o.name).join(' / '),
                                cantons: !this.isAdmin || document.cantons.length == 0 ? "-" : document.cantons.map(c => c[this.$i18n.locale]).join(' / '),
                                jobNames: !this.isAdmin || document.jobNames.length == 0 ? "-" : document.jobNames.map(j => j[this.$i18n.locale]).join(' / '),
                            })
                        });
                    } else {
                        mappedDocuments.push({
                            name: key,
                            documents: value.map(document => ({
                                name: ctx.localName(document.name),
                                size: document.size,
                                organizations: !this.isAdmin || document.organizations.length == 0 ? "-" : document.organizations.map(o => o.name).join(' / '),
                                cantons: !this.isAdmin || document.cantons.length == 0 ? "-" : document.cantons.map(c => c[this.$i18n.locale]).join(' / '),
                                jobNames: !this.isAdmin || document.jobNames.length == 0 ? "-" : document.jobNames.map(j => j[this.$i18n.locale]).join(' / '),
                            }))
                        })
                    }
                }

                return mappedDocuments
            }
            
            return []
        },

        orderedOrganizations() {
            if (this.organizations) {
                return [...this.organizations].sort((a, b) => 
                    (a.name > b.name) ? 1 : 
                    (b.name > a.name) ? -1 : 0)
            }

            return []
        },

        orderedCantons() {
            if (this.cantons) {
                return [...this.cantons].sort((a, b) => 
                    (a[this.$i18n.locale] > b[this.$i18n.locale]) ? 1 : 
                    (b[this.$i18n.locale] > a[this.$i18n.locale]) ? -1 : 0)
            }

            return []
        },

        orderedJobs() {
            if (this.jobs) {
                return [...this.jobs].sort((a, b) => 
                    (a[this.$i18n.locale] > b[this.$i18n.locale]) ? 1 : 
                    (b[this.$i18n.locale] > a[this.$i18n.locale]) ? -1 : 0)
            }

            return []
        },
    },
    methods: {
        ...mapActions('app', [ 'addDocument', 'loadDocuments', 'deleteDocument', 'downloadDocument' ]),

        submit() {
            this.addDocument({ "doc": this.file, "folder": this.folder, "filters": {
                organizations: this.filtersOrganization,
                cantons: this.filtersCanton,
                jobs: this.filtersJob
            }})
            this.file = null
            this.clearFilters()
        },

        localName(path) {
            let chunks = path.split("/")
            return chunks[chunks.length - 1]
        },

        getFolder(filename) {
            let chunks = filename.split('/')
            if (chunks.length > 2) {
                return chunks[1]
            }

            return ""
        },
        
        rowClass(item) {
            if (item && item.documents) {
                return 'light'
            }
            
            return ''
        },

        path(localName) {
            let path = localName

            this.documents.forEach(document => {
                if (document.name.includes(localName))
                {
                    path = document.name
                }
            });

            return path
        },

        clearFilters() {
            this.filtersOrganization = [ '', '', '', '' ]
            this.filtersCanton = [ '', '', '', '' ]
            this.filtersJob = [ '', '', '', '' ]
        },

        setDeletingEmployee(docName) {
            this.deletingDocument = docName
        },

        destroyDocument() {
            this.deleteDocument(this.deletingDocument)
        }
    }
}

</script>

<style scoped>
    #pageContainer {
        padding: 50px 100px;
        background-color: white;
        margin: auto;
        margin-top: 56px;
    }
</style>