<template>
    <div id="background">

        <!-- Register Form -->
        <div v-if="creation" id="employee-form">

            <!-- Tooltip -->
            <button v-if="help" class="btn btn-info rounded float-right" data-toggle="modal" data-target="#helpModal">
                <i class="fas fa-question my-1"></i>
            </button>
            <Modal id="helpModal"
                :title="$t('forms.content.help-description')"
                :cancelMessage="$t('forms.content.close')">
                
                <textarea rows="12" class="form-control" v-model="help" type="text" readonly/>
            
            </Modal>

            <!-- Title -->
            <h2 class="mb-4 text-center">{{ $t('register.form.title') }}</h2>

            <!-- Employee Form -->
            <EmployeeDetailsForm v-model="employee.employeeDetails" :v="$v.employee.employeeDetails" />

            <!-- Job Form -->
            <JobDetailsForm v-for="(jobDetails, i) in employee.jobDetailsList" :key="i"
                :id="i" 
                :count="employee.jobDetailsList.length"
                :value="jobDetails"
                :v="$v.employee.jobDetailsList.$each[i]"
                v-on:change="changePickedJob"
                v-on:delete="deleteJob" />

            <!-- Add job form button -->
            <button @click="addJobDetails" class="btn btn-info w-100 my-4 font-weight-bold" :disabled="employee.jobDetailsList.length >= 4" type="button">
                <div v-if="employee.jobDetailsList.length < 4">
                    <i class="fas fa-plus fa-lg mt-1 mr-2"></i>
                    {{ $t('register.form.add-job-button') }}
                </div>
                <div v-else>{{ $t("register.form.add-job-button-limit") }}</div>
            </button>

            <!-- Error banner -->
            <div v-if="errors.register" class="alert alert-danger text-center my-2" role="alert">{{ errors.register }}</div>

            <!-- Cancel or register -->
            <div class="form-row">
                <div class="form-group col-sm-6 m-0">

                    <!-- Cancel -->
                    <button @click="$router.push('login')" class="btn btn-secondary w-100 m-0 font-weight-bold" type="button">{{ $t("register.form.cancel-button") }}</button>

                </div>
                <div class="form-group col-sm-6 m-0">

                    <!-- Register -->
                    <button button @click="submit" class="btn btn-info w-100 m-0 font-weight-bold" type="button" :disabled="isLoading">
                        <div v-if="!isLoading" class="pointer m-0">{{ $t("register.form.register-button") }}</div>
                        <half-circle-spinner v-else class="mx-auto"
                            :animation-duration="800"
                            :size="23"
                            :color="'#ffffff'"
                        />
                    </button>
                </div>
            </div>
        </div>

        <!-- Consent Form -->
        <div v-else id="consent-form">

            <!-- Title -->
            <h2 class="mb-4 text-center">{{ $t("register.form.consent-title") }}</h2>

            <!-- Form -->
            <textarea rows="20" class="text-justify w-100 p-3 border-secondary rounded" v-model="form" readonly />

            <!-- Checkbox -->
            <div class="form-check mt-2">
                <input id="flexCheckChecked" class="form-check-input" type="checkbox" v-model="checked">
                <label class="form-check-label unselectable" for="flexCheckChecked">
                    {{ $t("register.form.read") }}
                </label>
            </div>
            
            <!-- Register -->
            <button class="btn btn-info mt-3 font-weight-bold w-100 mt-2" @click="creation = true" :disabled="!checked">
                <div v-if="!isLoading" class="pointer m-0">{{ $t("register.form.create") }}</div>
                <half-circle-spinner v-else class="mx-auto"
                    :animation-duration="800"
                    :size="23"
                    :color="'#ffffff'"
                />
            </button>

            <!-- Back to login -->
            <label @click="$router.push('login')" class="text-info text-center w-100 pointer user-select-none mt-2"><u>{{ $t('register.form.back-to-login') }}</u></label>

        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { HalfCircleSpinner } from 'epic-spinners'
import EmployeeDetailsForm from '../Forms/EmployeeDetailsForm'
import JobDetailsForm from '../Forms/JobDetailsForm'
import Modal from '../Forms/Modal'

export default {
    components: {
        HalfCircleSpinner,
        EmployeeDetailsForm,
        JobDetailsForm,
        Modal
    },
    data() {
        return {
            checked: false,
            creation: false,
            form: "",
            help: "",
            jobDetails: {
                picked: 0,
                email: "",
                percentage: "",
                organization: "",
                job_name: ""
            },
            employee: {
                employeeDetails: {
                    first_name: "",
                    last_name: "",
                    gender: "",
                    birthday: "",
                    address: {
                        phone: "",
                        address: "",
                        zip: "",
                        city: "",
                        country: ""
                    }
                },
                jobDetailsList: []
            }
        }
    },
    validations: {
        employee: {
            employeeDetails: {
                first_name: { required },
                last_name: { required },
                gender: { required },
                birthday: { required },
                address: {
                    phone: { },
                    address: { required },
                    zip: { required },
                    city: { required },
                    country: { required }
                }
            },
            jobDetailsList: {
                $each: {
                    email: { required, email },
                    percentage: { required },
                    organization: { required },
                    job_name: { required },
                }
            }
        }
    },
    mounted() {
        this.addJobDetails()
        this.getFormData()
        this.loadSettings()
    },
    watch: {
        settings: function(newSettings) {
            newSettings.forEach(setting => {
                if (setting.name == "registerForm") {
                    this.form = setting.value
                }
                if (setting.name == "registerHelp") {
                    this.help = setting.value
                }
            });
        }
    },
    computed: {
        ...mapState('auth', [ 'errors', 'isLoading' ]),
        ...mapState('app', [ 'settings' ])
    },
    methods: {
        ...mapActions('auth', [ 'resetErrors', 'register' ]),
        ...mapActions('app', [ 'getFormData', 'loadSettings' ]),

        validationStatus: function(validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },

        addJobDetails: function() {
            let jobDetails = JSON.parse(JSON.stringify(this.jobDetails))
            this.employee.jobDetailsList.push(jobDetails)
        },

        changePickedJob: function(id) {
            this.employee.jobDetailsList.forEach(jobDetails => {
                jobDetails.picked = id
            })
        },

        deleteJob: function (id) {
            // Delete job
            this.employee.jobDetailsList.splice(id, 1);

            // Reset picked main job as first if previous main job is deleted
            if (this.employee.jobDetailsList.length > 0 && this.employee.jobDetailsList[0].picked === id) {
                this.changePickedJob(0)
            }

            // Shift picked job if greater than deleted job
            if (this.employee.jobDetailsList.length > 0 && this.employee.jobDetailsList[0].picked > id) {
                this.changePickedJob(this.employee.jobDetailsList[0].picked - 1)
            }
        },

        submit: function() {
            // Reset errors in any case
            this.resetErrors();

            // Prevent login if valdiation contains errors
            this.$v.$touch();
            if (this.$v.$pending || this.$v.$error) return;

            // Reset validation otherwise
            this.$v.$reset();
            
            // Try to login
            this.register(this.employee);
        }
    }
}
</script>

<style scoped>
    #background {
        background-color: white;
        min-height: calc(100vh - 60px);
        position: relative;
        padding: 40px;
    }
    #employee-form {
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        width: 100%;
        max-width: 700px;
        margin: auto; 
        padding: 40px;
    }
    #consent-form {
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        width: 800px;
        margin: auto; 
        padding: 40px;
    }
    #logo {
        width: 80px;
        margin-left: 120px;
    }
    .pointer {
        cursor: pointer;
    }
    textarea {
        resize: none;
    }
</style>