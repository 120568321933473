<template>
    <div class="mb-4">
        <h5 class="text-info mt-3">{{ $t('register.form.title-employee') }}</h5>
        <div class="border rounded p-3">
            
            <div class="form-row">
                <div class="form-group col-sm-6 m-0">

                    <!-- First name -->
                    <label class="form-label mt-2 w-50">
                        {{ $t('forms.content.first-name') }}
                        <span v-if="!v.first_name.required">*</span>
                    </label>
                    <input class="form-control" :class="{ 'is-invalid': validationStatus(v.first_name) }" v-model="value.first_name" type="text" />
                    <div v-if="!v.first_name.required" class="invalid-feedback">{{ $t('forms.validation.first-name') }}</div>

                </div>
                <div class="form-group col-sm-6 m-0">

                    <!-- Last name -->
                    <label class="form-label mt-2 w-50">
                        {{ $t('forms.content.last-name') }}
                        <span v-if="!v.last_name.required">*</span>
                    </label>
                    <input class="form-control" :class="{ 'is-invalid': validationStatus(v.last_name) }" v-model="value.last_name" type="text" />
                    <div v-if="!v.last_name.required" class="invalid-feedback">{{ $t('forms.validation.last-name') }}</div>

                </div>
            </div>

            <div class="form-row mt-2">
                <div class="form-group col-sm-4 m-0">

                    <!-- Gender -->
                    <label class="form-label w-50">
                        {{ $t('forms.content.gender') }}
                        <span v-if="!v.gender.required">*</span>
                    </label>
                    <select class="form-control" :class="{ 'is-invalid': validationStatus(v.gender) }" v-model="value.gender">
                        <option value="">{{ $t('forms.content.select') }}</option>
                        <option v-for="gender in genders" :key="gender.fr" :value="gender">
                            {{ gender[$i18n.locale] }}
                        </option>
                    </select>
                    <div v-if="!v.gender.required" class="invalid-feedback">{{ $t('forms.validation.gender') }}</div>

                </div>
                <div class="form-group col-sm-8 m-0">

                    <!-- Birthday -->
                    <label class="form-label w-50">
                        {{ $t('forms.content.birthday') }}
                        <span v-if="!v.birthday.required">*</span>
                    </label>
                    <input class="form-control" :class="{ 'is-invalid': validationStatus(v.birthday) }" v-model="value.birthday" type="date" />
                    <div v-if="!v.birthday.required" class="invalid-feedback">{{ $t('forms.validation.birthday') }}</div>

                </div>
            </div>

            <!-- Address -->
            <AddressForm v-model="value.address" :v="v.address" />
            
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AddressForm from './AddressForm.vue'

export default {
    components: {
        AddressForm
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        v: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState('app', [ 'genders' ]),
        ...mapState('auth', [ 'errorMessage', 'isLoading', 'jobs' ]),
    },
    methods: {
        ...mapActions('auth', [ 'resetErrors', 'login' ]),

        validationStatus: function(validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },
    }
}
</script>

<style scoped>

</style>