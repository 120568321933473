<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel" v-text="title" />
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot/>
                </div>
                <div class="modal-footer">
                    <button v-if="cancelMessage" type="button" class="btn btn-secondary" data-dismiss="modal" v-text="cancelMessage" v-on:click="$emit('cancel')" />
                    <button v-if="confirmMessage" type="button" class="btn btn-info" data-dismiss="modal" v-text="confirmMessage" v-on:click="$emit('confirm')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        title: String,
        content: String,
        cancelMessage: String,
        confirmMessage: String
    },
    computed: {
        ...mapState('auth', [ 'errorMessage', 'isLoading' ]),
        ...mapState('app', [ 'jobs', 'organizations' ])
    },
    methods: {
        ...mapActions('auth', [ 'resetErrors', 'login' ])
    }
}
</script>

<style scoped>

</style>