import de from './de.json'
import en from './en.json'
import fr from './fr.json'

export const defaultLocale = 'fr'

export const languages = {
    de: de,
    en: en,
    fr: fr
  }