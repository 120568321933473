import AuthService from '../services/AuthService';
import router from '../router'

import { i18n } from '../app.js' 

const state = { 
    user: JSON.parse(localStorage.getItem('user')) || null,
    langs: ['fr', 'de', 'en'],
    registeredEmail: "",
    errors: {
        login: "",
        register: "",
        data: "",
        updateEmail: "",
        updatePassword: "",
        resetPassword: ""
    },
    loading: {
        updateEmail: false,
        updatePassword: false,
        resetPassword: false
    },
    isLoading: false,
    role: null,
    verificationSuccess: false,
    verificationFailure: false,
    registerSuccess: false,
    resetSuccess: false
}

const getters = { 
    isLoggedIn: state => state.user != null,
    isAdmin: state => state.user ? state.user.role == 'admin' : false,
    isFirstUse: state => state.user ? state.user.first_use : false
}

const mutations = { 

    reset_errors: (state) => {
        state.errors.login = ""
        state.errors.register = ""
        state.errors.data = ""

        state.registerSuccess = false
        state.verificationFailure = false
        state.verificationSuccess = false
        state.resetSuccess = false
    },

    update_employee: (state, employee) => {
        state.user.employee = employee
    },

    reset_success: (state) => {
        state.resetSuccess = true
    },

    password_changed: (state) => {
        state.user.first_use = false
        localStorage.setItem('user', JSON.stringify(state.user))
    },

    // Verification
    verify: (state, result) => {
        state.verificationSuccess = result
        state.verificationFailure = !result
    },

    // Request state handler
    request: (state, operation) => {
        state.errors[operation] = ""
        state.loading[operation] = true
    },
    success: (state, operation) => {
        state.loading[operation] = false
    },
    error: (state, { operation, error }) => {
        state.errors[operation] = error
        state.loading[operation] = false
    },

    // Login
    auth_request: (state) => {
        state.isLoading = true
    },
    auth_success: (state, user) => {
        state.errors.login = ""
        state.user = user
        state.isLoading = false
    },
    auth_error: (state, errorMessage) => {
        state.errors.login = errorMessage,
        state.isLoading = false
    },

    // Register
    register_request: (state) => {
        state.isLoading = true
    },
    register_success: (state, data) => {
        state.errors.register = ""
        state.isLoading = false
        state.registerSuccess = true
        state.registeredEmail = data.email
    },
    register_error: (state, errorMessage) => {
        state.errors.register = errorMessage,
        state.isLoading = false
    },

    // Logout
    logout: (state) => {
        state.errors.login = ""
        state.user = null
    },

    select_role: (state, newRole) => {
        state.role = newRole
    }
}

const actions = { 

    resetErrors({ commit }) {
        commit('reset_errors')
    },

    updateEmployee({ state, commit }, employee) {
        commit('update_employee', employee)
        localStorage.setItem('user', JSON.stringify(state.user))
    },

    login({ commit }, user) {
        commit('auth_request')
        AuthService.login(user)
            .then(response => {
                commit('auth_success', response.data)

                localStorage.setItem('loggedIn', "true")
                localStorage.setItem('user', JSON.stringify(response.data))

                router.push('/documents');
            })
            .catch(error => {
                switch (error.response.status) {
                    case 403:
                        commit('auth_error', i18n.t("login.errors.credentials"))
                        break
                    case 418:
                        commit('auth_error', i18n.t("login.errors.unverified"))
                        break
                    default:
                        commit('auth_error', i18n.t("login.errors.default", { status: error.response.status }))
                        break
                }
            })
    },

    change({ commit }, email) {
        commit('request', 'resetPassword')
        AuthService.change(email)
            .then(_ => {
                commit('success', 'resetPassword')
                commit('reset_success')
            })
            .catch(error => {
                switch (error.response.status) {
                    case 403:
                        commit('error', { operation: 'resetPassword', error: i18n.t("login.errors.reset") })
                        break
                    case 418:
                        commit('error', { operation: 'resetPassword', error: i18n.t("login.errors.email") })
                        break
                    default:
                        commit('error', { operation: 'resetPassword', error: i18n.t("login.errors.default", { status: error.response.status }) })
                        break
                }
            })
    },

    verify({ commit }, hash) {
        AuthService.verify(hash)
            .then(_ => {
                commit('verify', true)
            })
            .catch(_ => {
                commit('verify', false)
            })
    },

    logout({ commit }) {
        AuthService.logout()
            .then(_ => {
                commit('logout')
                localStorage.setItem('loggedIn', "false")
                localStorage.removeItem('user')

                router.push('/login')
            })
            .catch(_ => {
                // Logout error
            })
    },

    register({ commit }, user) {
        commit('register_request')
        AuthService.register(user)
            .then(response => {
                commit('register_success', response.data)
                router.push('/login')
            })
            .catch(error => {
                switch (error.response.status) {
                    case 400:
                        commit('register_error', i18n.t("register.errors.missing"))
                        break;
                    case 409:
                        commit('register_error', i18n.t("register.errors.unique"))
                        break;
                    case 417:
                        commit('register_error', i18n.t("register.errors.email-missing"))
                        break;
                    case 418:
                        commit('register_error', i18n.t("register.errors.email-error"))
                        break;
                    default:
                        commit('register_error', i18n.t("register.errors.default", { status: error.response.status }))
                        break;
                }
            })
    },

    updateEmail({ commit }, { id, email }) {
        commit('request', 'updateEmail')
        AuthService.updateEmail(id, email)
            .then(_ => {
                commit('success', 'updateEmail')
            })
            .catch(error => {
                switch (error.response.status) {
                    case 402:
                        commit('error', { operation: 'updateEmail', error: i18n.t("forms.errors.email") })
                        break;
                    case 403:
                        commit('error', { operation: 'updateEmail', error: i18n.t("forms.errors.user") })
                        break;
                    default:
                        commit('register_error', i18n.t("register.errors.default", { status: error.response.status }))
                        break;
                }
            })
    },

    updatePassword({ getters, commit }, { id, infos }) {
        commit('request', 'updatePassword')
        AuthService.updatePassword(id, infos)
            .then(_ => {
                commit('success', 'updatePassword')

                if (getters.isFirstUse) {
                    commit('password_changed')
                    router.push('/documents')
                } 
            })
            .catch(error => {
                switch (error.response.status) {
                    case 402:
                        commit('error', { operation: 'updatePassword', error: i18n.t("forms.errors.password") })
                        break;
                    case 403:
                        commit('error', { operation: 'updatePassword', error: i18n.t("forms.errors.user") })
                        break;
                    default:
                        commit('register_error', i18n.t("register.errors.default", { status: error.response.status }))
                        break;
                }
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}  