export default {

    employees: function() {
        return axios.get('/api/employees', { responseType: 'json' })
    },

    deleteEmployee: function(id) {
        return axios.delete(`/api/employees/${id}`, { id, responseType: "json" });
    },

    editEmployee: function(id, employee) {
        return axios.put(`/api/employees/${id}`, { id, employee, responseType: 'json' })
    },
    
}   

