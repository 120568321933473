require('./bootstrap');
require('../css/app.css')

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Vuelidate from 'vuelidate'
import BootstrapVue from 'bootstrap-vue';
import store from './store/index'
import router from './router.js'
import { languages } from '../locales/index.js'
import { defaultLocale } from '../locales/index.js'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

window.Vue = require('vue');

Vue.use(VueI18n)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.component('app-component', require('./components/App.vue').default);

const messages = Object.assign(languages);

axios.interceptors.response.use(null, error => {
    if (error.response.status == 401) {
        store.commit("auth/logout");
        router.push("/login");
    }

    return Promise.reject(error);
});

const i18n = new VueI18n({
    locale: defaultLocale, 
    messages, 
})

const app = new Vue({
    i18n,
    router: router,
    el: '#app',
    store
})

export { i18n };