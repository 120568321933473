import Vue from "vue"
import Vuex from "vuex"
import Axios from 'axios'

window.Vuex = require('vuex');
Vue.use(Vuex);
Vue.prototype.$http = Axios;

import auth from './auth.store'
import app from './app.store'

export default new Vuex.Store({
    modules: {
        auth,
        app
    },
    strict: false
})