import Vue from 'vue'
import store from './store/index.js'
import Router from 'vue-router'

import LoginPage from './components/Auth/LoginPage.vue'
import RegisterPage from './components/Auth/RegisterPage.vue'
import ProfilePage from './components/Auth/ProfilePage.vue'
import AccountPage from './components/Auth/AccountPage.vue'
import VerifyPage from './components/Auth/VerifyPage.vue'
import FirstUsePage from './components/Auth/FirstUsePage.vue'
import DocumentsPage from './components/DocumentsPage.vue'
import EmployeesPage from './components/EmployeesPage.vue'
import OrganizationsPage from './components/OrganizationsPage.vue'
import SettingsPage from './components/SettingsPage.vue'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [
        // Root page (host)
        {
            path: '/', 
            name: 'root',
            redirect: '/documents'
        },
        // Login page (host/login)
        {
            path: '/login', 
            name: 'login',
            components: { PageContent: LoginPage },
            meta: { requiresOut: true }
        },
        // Verify page (host/verify)
        {
            path: '/verify', 
            name: 'verify',
            components: { PageContent: VerifyPage }
        },
        // Register page (host/register)
        {
            path: '/register', 
            components: { PageContent: RegisterPage }
        },
        // First use page (host/first)
        {
            path: '/first', 
            components: { PageContent: FirstUsePage },
            meta: { requiresAuth: true }
        },
        // Profile page (host/profile)
        {
            path: '/profile', 
            components: { PageContent: ProfilePage },
            meta: { requiresAuth: true, requiresEmployee: true, requiresFirstUse: true }
        },
        // Account page (host/account)
        {
            path: '/account', 
            components: { PageContent: AccountPage },
            meta: { requiresAuth: true, requiresFirstUse: true }
        },
        // Documents Page (host/documents)
        {
            path: '/documents',
            components: { PageContent: DocumentsPage },
            meta: { requiresAuth: true, requiresFirstUse: true }
        },
        // Employees Page (host/employees)
        {
            path: '/employees', 
            components: { PageContent: EmployeesPage },
            meta: { requiresAuth: true, requiresAdmin: true }
        },
        // Organizations Page (host/organizations)
        {
            path: '/organizations',
            components: { PageContent: OrganizationsPage },
            meta: { requiresAuth: true, requiresAdmin: true }
        },
        // Settings Page (host/settings)
        {
            path: '/settings',
            components: { PageContent: SettingsPage },
            meta: { requiresAuth: true, requiresAdmin: true }
        }
    ]
})

router.beforeEach((to, from, next) => {

    // Check login status
    if (to.matched.some(record => record.meta.requiresAuth)) 
    {
        if (!store.getters['auth/isLoggedIn']) 
        {
            next('/login')
        }
    } 

    // Check logout status
    if (to.matched.some(record => record.meta.requiresOut)) 
    {
        if (store.getters['auth/isLoggedIn']) 
        {
            store.dispatch('auth/logout');
            return
        }
    } 


    // Check admin routes
    if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (!store.getters['auth/isAdmin']) {
            next('/documents')
        }
    }

    // Check employee routes
    if (to.matched.some(record => record.meta.requiresEmployee)) {
        if (store.getters['auth/isAdmin']) {
            next('/documents')
        }
    }

    // Check first use
    if (to.matched.some(record => record.meta.requiresFirstUse)) 
    {
        if (store.getters['auth/isFirstUse']) 
        {
            next('/first')
        }
    }

    // Continue
    next()
})

export default router