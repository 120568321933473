<template>
    <b-navbar fixed="top" toggleable="lg" type="dark" variant="info">

        <b-navbar-brand to="/documents">144-Directory.ch</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>

            <b-navbar-nav v-if="isLoggedIn">
                
                <b-nav-item v-if="!isFirstUse" to="/documents" @click="resetEditings">{{ $t('nav.documents') }}</b-nav-item>
                <b-nav-item v-if="isAdmin" to="/employees" @click="resetEditings">{{ $t('nav.employees') }}</b-nav-item>
                <b-nav-item v-if="isAdmin" to="/organizations" @click="resetEditings">{{ $t('nav.organizations') }}</b-nav-item>
                <b-nav-item v-if="isAdmin" to="/settings" @click="resetEditings">{{ $t('nav.parameters') }}</b-nav-item>

            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">

            <b-nav-item-dropdown right>
                <template #button-content>
                    {{ $t('nav.locales') }}
                </template>
                <b-dropdown-item v-for="(lang, i) in langs" :key="i" :value="lang" @click="selectLanguage(lang)">
                    {{ languages[lang] }}
                </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right v-if="isLoggedIn"> 

                <template v-if="user.employee" #button-content>
                    {{ user.employee.last_name + " " + user.employee.first_name }}
                </template>

                <template v-else-if="user.employer" #button-content>
                    {{ user.employer.last_name + " " + user.employer.first_name }}
                </template>

                <template v-else #button-content>
                    {{ $t('nav.admin') }}
                </template>

                <b-dropdown-item v-if="!isAdmin && !isFirstUse" to="/profile">{{ $t('nav.profile') }}</b-dropdown-item>
                <b-dropdown-item v-if="!isFirstUse" to="/account">{{ $t('nav.account') }}</b-dropdown-item>
                <b-dropdown-item v-on:click="logout">{{ $t('nav.logout') }}</b-dropdown-item>
            </b-nav-item-dropdown>

            </b-navbar-nav>

        </b-collapse>
    </b-navbar>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            languages: {
                "en": "English",
                "fr": "Français",
                "de": "Deutsch",
            }
        }
    },
    computed: {
        ...mapState('auth', [ 'user', 'langs' ]),
        ...mapGetters('auth', [ 'isLoggedIn', 'isAdmin', 'isFirstUse' ]),
        versionTag() {
            return process.env.MIX_APP_VERSION
        }
    },
    methods: {
        ...mapActions('auth', [ 'logout' ]),
        ...mapActions('app', [ 'resetEditings' ]),

        selectLanguage(language) {
            this.$i18n.locale = language
            localStorage.setItem('lang', language)
        }
    },
}
</script>