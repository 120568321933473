<template>
    <div id="background">

        <!-- Form -->
        <form id="form" v-on:submit.prevent="submit" novalidate>

            <!-- Title -->
            <h2 class="mb-4 text-center">{{ $t("login.form.title") }}</h2>

            <!-- Reset banner -->
            <div v-if="resetSuccess" class="alert alert-success text-center my-2" role="alert">{{ $t('login.form.reset-success') }}</div>

            <!-- Success banner -->
            <div v-if="registerSuccess" class="alert alert-success text-center my-2" role="alert">{{ $t('login.form.register-success', { email: registeredEmail }) }}</div>

            <!-- Error banner -->
            <div v-if="errors.login" class="alert alert-danger text-center" role="alert">{{ errors.login }}</div>
            <div v-if="errors.resetPassword" class="alert alert-danger text-center" role="alert">{{ errors.resetPassword }}</div>

            <div class="form-group">

                <!-- Email -->
                <label class="form-label mt-2">{{ $t('login.form.email') }}</label>
                <input class="form-control" :class="{ 'is-invalid': validationStatus($v.email) }" v-model="email" type="text" />
                <div v-if="!$v.email.required" class="invalid-feedback">{{ $t('login.validation.email') }}</div>

                <!-- Password -->
                <label class="form-label mt-2">{{ $t('login.form.password') }}</label>
                <div class="input-group">
                    <input v-if="showPassword" class="form-control w-75 mr-0" :class="{ 'is-invalid': validationStatus($v.password) }" v-model="password" type="text" />
                    <input v-else class="form-control w-75 mr-0" :class="{ 'is-invalid': validationStatus($v.password) }" v-model="password" type="password" />
                    <div class="input-group-append" style="cursor: pointer;" @click="showPassword = !showPassword">
                        <div class="input-group-text">
                            <i class="fas fa-eye" id="togglePassword"></i>
                        </div>
                    </div>
                    <div v-if="!$v.password.required && !validationLock" class="invalid-feedback">{{ $t('login.validation.password') }}</div>
                </div>
                
            </div>

            <!-- Forgot password -->
            <label v-if="!loading.resetPassword" class="text-info text-right w-100 pointer user-select-none mb-4" @click="reset">{{ $t('login.form.forgot') }}</label>
            <half-circle-spinner v-else class="mx-auto mb-4"
                :animation-duration="800"
                :size="23"
                :color="'#000000'"
            />

            <!-- Login -->
            <button class="btn btn-info w-100 my-2 font-weight-bold" type="submit" :disabled="isLoading">
                <div v-if="!isLoading" class="pointer m-0">{{ $t("login.form.login") }}</div>
                <half-circle-spinner v-else class="mx-auto"
                    :animation-duration="800"
                    :size="23"
                    :color="'#ffffff'"
                />
            </button>

            <!-- Register -->
            <label @click="$router.push('register')" class="text-info text-center w-100 pointer user-select-none mb-0"><u>{{ $t('login.form.register') }}</u></label>
            
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { HalfCircleSpinner } from 'epic-spinners'

export default {
    components: {
        HalfCircleSpinner
    },
    data() {
        return {
            password: '',
            email: '',
            validationLock: false,
            showPassword: false
        }
    },
    validations: {
        password: { required },
        email: { required },
    },
    computed: {
        ...mapState('auth', [ 'loading', 'errors', 'isLoading', 'registerSuccess', 'resetSuccess', 'registeredEmail' ]),
    },
    methods: {
        ...mapActions('auth', [ 'resetErrors', 'login', 'change' ]),

        validationStatus: function(validation) {
            return typeof validation != "undefined" ? validation.$error : false;
        },

        reset: function() {
            this.validationLock = true

            // Reset validation
            this.$v.$reset()

             // Reset errors in any case
            this.resetErrors()

            // Prevent login if valdiation contains errors
            this.$v.email.$touch()
            if (this.$v.email.$pending || this.$v.email.$error) return
            
            // Try to login
            this.change(this.email)
        },

        submit: function() {
            this.validationLock = false

            // Reset errors in any case
            this.resetErrors()

            // Prevent login if valdiation contains errors
            this.$v.$touch();
            if (this.$v.$pending || this.$v.$error) return

            // Reset validation otherwise
            this.$v.$reset()
            
            // Try to login
            let user = {
                email: this.email,
                password: this.password
            }
            this.login(user)
        }
    }
}
</script>

<style scoped>
    #background {
        background-color: white;
        min-height: calc(100vh - 60px);
        position: relative;
    }
    #form {
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        width: 420px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -260px 0 0 -210px; /* apply negative top and left margins to truly center the element */
        padding: 40px;
    }
    #logo {
        width: 80px;
        margin-left: 120px;
    }
    .pointer {
        cursor: pointer;
    }
</style>